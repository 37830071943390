import * as React from 'react';
import { Button, StyleSheet, Text, View } from 'react-native'


// El Tailwind se importa desde la librería customizada en styles
import tw from '../../styles/tailwind';


// Para esquemas de color
import { useColorScheme } from 'react-native'



// react-native-responsive-ui
// TODO: Meter esto en librería o styles
// import { mediaQuery, useDimensions } from "react-native-responsive-ui";



// Referencia 
// https://github.com/jaredh159/tailwind-react-native-classnames/tree/v2


export default function StylesExamplesScreen({ route, navigation }) {
  const colorScheme = useColorScheme() // "light" / "dark"


  // react-responsive
  // const { width, height } = useDimensions();
  // const isMobile = mediaQuery({ maxWidth: 767 }, width, height)
  // const isPortrait = mediaQuery({ orientation: 'portrait' }, width, height)

  

  return (
    <View style={tw`bg-gray-200 flex-1`}>

      <View style={tw`max-w-screen-md	w-full self-center py-5 px-5`}>
        <View style={tw.style( // También se puede llamar tw.style() y 
          `bg-white py-10 rounded-lg border-2 bg-ocean-green-200`,
          // { 'px-10 py-8' : ! isMobile }, // 
          // isMobile && 'px-4', // expresión booleana
          { borderColor: "#e7edf3" }, // o de la manera tradicional
        )}>
          <Text>Esto está estilizado con Tailwind! (y además responsivo)</Text>
        </View>

        <Text style={tw`text-primary pt-5 px-5`}>Color Primario (definido en tailwind.config.js):</Text>
        <Text style={tw`text-primary py-5 px-5`}>{ tw.color('primary') }</Text>
        {/* Más info de esta librería en https://github.com/jaredh159/tailwind-react-native-classnames */}



        <View>
          {/* <Text>Tamaño pantalla: Width: {width} - height: {height}</Text> */}
          {/* <Text>Esto {isMobile ? 'es' : 'no es'} mobile</Text>
          <Text>Orientación: {isPortrait ? 'portrait' : 'landscape'}</Text> */}
          <Text>Esquema de color: {colorScheme}</Text>
        </View>
      </View>
    </View>
  );
}
