import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';

/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';


export default function HeaderMetodoPago({navigation}) {
  // const route = useRoute();
  // const { idEmpresa, codigoActivacion } = route.params;

    const goToAltaCompDomi= () => {
        navigation.navigate('AltaDocumentosCompDomicilioScreen.Index');
    }

  
    return(
      <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
        <View style={tw`flex-row `}>
          <Pressable style={tw`mr-2 pt-9`} onPress={goToAltaCompDomi}>
            <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
          </Pressable>
          <Text style={tw`text-primary text-3xl mt-8 font-500`}>Método de Pago</Text>
      </View>
      <Text style={tw`mt-3 text-xl pl-10`}>Selecciona tu método de pago preferido</Text>
  </View>
    );
  };

  
  