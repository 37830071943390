import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function InfoIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 42.08 42.08"
            {...props}
        >
            <Path
            d="M21.04 0C9.42 0 0 9.42 0 21.04s9.42 21.04 21.04 21.04 21.04-9.42 21.04-21.04S32.66 0 21.04 0Zm3.07 31.9c0 1.13-.92 2.05-2.05 2.05h-2.04c-1.13 0-2.05-.92-2.05-2.05V19.56c0-1.13.92-2.05 2.05-2.05h2.04c1.13 0 2.05.92 2.05 2.05V31.9Zm-3.07-17.64a3.07 3.07 0 1 1 .001-6.141 3.07 3.07 0 0 1-.001 6.141Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )
}