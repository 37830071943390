import { StatusBar, } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, View, Pressable, TextInput } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from '../../hooks/useCachedResources';
import useColorScheme from '../../hooks/useColorScheme';
import Navigation from '../../navigation';
import Toast, { ToastProvider } from 'react-native-toast-notifications'
//  react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()
// Configuración twrnc
import tw from "../../styles/tailwind";
import { useDeviceContext } from 'twrnc'
//-------------------------Configuración redux-------------------------------------------//
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../../store/configureStore";
const { store, persistor } = configureStore();
//-------------------------Configuración de API-------------------------------------------//
import api from "../../utils/api";
import Constants from 'expo-constants'
/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';

import HeaderValidar from './HeaderValidar'
import ContentValidar from './ContentValidar'


export default function ValidarPagoScreen({navigation}) {
    /* Estado que valida si el pago fue correcto o no */
    let pagoValidado = true;
    return(
        <SafeAreaView style={tw`bg-white flex-1`}>
            <HeaderValidar navigation={navigation} />
            <ContentValidar navigation={navigation} />
        </SafeAreaView>
    );

    function goToInicio(){
        navigation.navigate('Inicio.Index');
    }
    function goToMetodoPago(){
        navigation.navigate('MetodoPagoScreen.Index');
    }
};


