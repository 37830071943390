import * as React from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';

import HeaderPagoOxxo from './HeaderPagoOxxo';
import FormaPagoOxxo from './FormaPagoOxxo';
import FooterPagoOxxo from './FooterPagoOxxo';


export default function PagoOxxoScreen({navigation}) {
  

    return (
      <View style={tw`bg-white flex-1`}>
        {/* <HeaderPagoOxxo navigation={navigation} /> */}
        <FormaPagoOxxo navigation={navigation} />
      </View>
    );
  }