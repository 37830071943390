import React from 'react';
import { View, Modal, Button, StyleSheet, Image, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import InfoIcon from '../../assets/icons/info';
import { Overlay } from '@rneui/themed';import Text from '../../components/Text';
;
const ModalAltaDocumentos = ({ visibleDocs, closeModalDocs }) => {
  let primaryColor = '#22c55e';
  return (
    <Overlay
    isVisible={visibleDocs}
    windowBackgroundColor="rgba(0,0,0,0.5)"
    overlayBackgroundColor="transparent"
    overlayStyle={tw`bg-white border-0 justify-center items-center rounded-3xl  mt-8 ml-5 mr-5`}
>
    <View style={[tw`bg-white pl-5 pr-5 pt-10 pb-10 items-center rounded-3xl`]}>
      <InfoIcon heigh={32} width={32} fill={primaryColor} style={tw`mb-4`} />
      <Text style={[tw`text-xl text-center text-primaryText`]} >Estos documentos contienen información relevante sobre la seguridad de tus datos, las cláusulas del servicio, los términos y condiciones, así como cualquier otra disposición legal que pueda aplicar.</Text>

      <Pressable 
        style={[tw`flex-1 py-3  bg-primary rounded-full mt-8`, {width: 300, height: 55,}]} 
        onPress={closeModalDocs}
      >
        <Text style={[tw`text-center text-white text-xl`]}>Cerrar</Text>
      </Pressable>
      
    </View>
</Overlay>
  );
};



export default ModalAltaDocumentos;