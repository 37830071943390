import React, { useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import MetodoPagoScreen from '../MetodoPago/Index';
import { useSelector, useDispatch } from "react-redux";
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';
import { subeImagenesDocumentosData,  } from '../../store/slices/imagenes';
import { setCleanDocumentoUrls } from '../../store/slices/documentos';
import FormData from 'form-data';
import axios from 'axios';

const FooterAltaDocumentosCompDomi = ({ navigation, uriCompDom, subirImagen, locationCompDom }) => {
  const [isLoading, setIsLoading] = useState(false); // Añade estado local para isLoading
  const ImagenesData = useSelector(state => state.imagenes);
  const DocumentosData = useSelector(state => state.documentos);
  const contratoData = useSelector(state => state.selectedDoc);
  const dispatch = useDispatch();

  const subeImagenesIdentificaciones = async (urlUploadDocs, formData) => {
    try {
      const response = await axios.post(urlUploadDocs, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.responseCode !== "ERROR") {
        const urlSendImagesToPerseo = []
        for (const key in response.data.responseObject) {
          if (response.data.responseObject.hasOwnProperty(key)) {
              const url = response.data.responseObject[key];
              urlSendImagesToPerseo.push(url)
          }
        }

        if(urlSendImagesToPerseo.length > 0){
          dispatch(subeImagenesDocumentosData(urlSendImagesToPerseo, contratoData.idActivacion, contratoData.id_empresa));
        }
        
        console.log('Imágenes subidas exitosamente');
        dispatch(setCleanDocumentoUrls());
        navigation.navigate('MetodoPagoScreen.Index');
      } else {
        console.error('Error al subir las imágenes:', response.data.responseMessage);
        alert(`Ocurrió un error al subir las imágenes: ${response.data.responseMessage}`);
      }
    } catch (error) {
      console.error('Error subiendo las imágenes', error);
      alert("Ocurrió un error al subir las imágenes");
    } finally {
      setIsLoading(false); // Asegúrate de desactivar el estado de carga
    }
  };

  const generaBlob = (uriBasa64, mimeType) => {
    const byteString = atob(uriBasa64.split(',')[1]); // Decodificar Base64
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mimeType });
  };

  const goToMetodoPago = async () => {
    if (!uriCompDom) {
      alert("Falta seleccionar imagen del Comprobante de Domicilio");
      return;
    }

    let documentosUrls = DocumentosData.documentosUrls;
    if (documentosUrls.length > 0) {
      const idActivacion = contratoData.idActivacion;
      const idEmpresa = contratoData.id_empresa;
      const numero_imagenes = DocumentosData.documentosUrls.length;
      const images = DocumentosData.documentosUrls;
      const formData = new FormData();

      formData.append('totalImagenes', numero_imagenes);

      images.forEach((item, index) => {
        const tipo_documento = item.tipo_documento;
        const uriData = item.uri;

        let mimeType = uriData.mimeType;
        let fileName = `${idEmpresa}_${idActivacion}_${tipo_documento}.jpg`;
        let KeyFile = `imagen${index+1}`;
        let blob = {};

        if (uriData.uri === undefined && tipo_documento === "comprobante_domicilio") {
          blob = generaBlob(uriCompDom, "image/jpeg");
          mimeType = "image/jpeg";
        } else {
          blob = generaBlob(uriData.uri, mimeType);
        }

        const tempUrl = URL.createObjectURL(blob);
        formData.append(KeyFile, blob, fileName);
        URL.revokeObjectURL(tempUrl);
      });

      setIsLoading(true); // Activa el estado de carga

      try {
        await axios.get('https://1csgxk1mh6.execute-api.us-east-2.amazonaws.com/dev/send-document/ts')
          .then((data) => {
            const urlUploadDocs = `https://perseoapp.duckdns.org/perseoAdminApp/ws_upload_images.php${data.data.urlComplement}&version=1.0`;
            subeImagenesIdentificaciones(urlUploadDocs, formData);
          })
          .catch((error) => {
            console.log("Error al traer Ts data");
            setIsLoading(false); // Desactiva el estado de carga en caso de error
          });

      } catch (error) {
        console.error('Error al subir las imágenes', error);
        setIsLoading(false); // Desactiva el estado de carga en caso de error
      }
    } else {
      navigation.navigate('MetodoPagoScreen.Index');
    }
  };
   /*if (images.length === 3) {
      // Crear un nuevo objeto FormData
      const formData = new FormData();

      // Agregar las imágenes al FormData
      images.forEach((imageUri, index) => {
        const fileName = imageUri.split('/').pop();
        const fileType = fileName.split('.').pop();
        
        formData.append('files', {
          uri: imageUri,
          name: `photo_${index}.${fileType}`,
          type: `image/${fileType}`,
        });
      });

      try {
        // Enviar el FormData a tu API
        const response = await axios.post('https://your-api-endpoint.com/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Imagenes subidas exitosamente', response.data);
      } catch (error) {
        console.error('Error subiendo las imágenes', error);
      }
    } else {
      console.log('Necesitas seleccionar 3 imágenes.');
    }*/

    /*await subirImagen()
    .then((result:any) =>{
      //console.log("**** result : ",result);
      //console.log("**** result : ",ImagenesData.imagenComprobanteDomicilio);
      if (result) {
        let documentosUrls =  [...DocumentosData.documentosUrls];
        //console.log("***** locationCompDom  : ", locationCompDom);
        //console.log("***** documentosurl antes : ", documentosUrls);
        if(locationCompDom !== "" && !documentosUrls.includes(locationCompDom)){
          [... documentosUrls, locationCompDom];
        }
        //console.log("***** documentosurl despues : ", documentosUrls);
        //console.log("Documentos URLS : ",documentosUrls);
        //console.log("datos documento recuperado : ",contratoData);
        //console.log("datos idEmpresa : ",contratoData.id_empresa);
        //console.log("datos idActivacion : ",contratoData.idActivacion);
        if(documentosUrls.length > 0){
          dispatch(subeImagenesDocumentosData(documentosUrls, contratoData.idActivacion, contratoData.id_empresa));
        }
        navigation.navigate('MetodoPagoScreen.Index');
      }
    }).catch((error:any) => {
      console.log("Error al subir comprobante de domicilio : ", error);
    })*/

    /*let success;
    if(ImagenesData.imagenComprobanteDomicilio){
      
    }else{
      success = await subirImagen();
    }*/
    

    /*if (success || ImagenesData.imagenComprobanteDomicilio) {
      const documentosUrls =  DocumentosData.documentosUrls;
      console.log("Documentos URLS : ",documentosUrls);
      console.log("datos documento recuperado : ",contratoData);
      console.log("datos idEmpresa : ",contratoData.id_empresa);
      console.log("datos numeroContrato : ",contratoData.numero_contrato);
      //dispatch(subeImagenesDocumentosData(documentosUrls, contratoData.numero_contrato, contratoData.id_empresa));
      //navigation.navigate('MetodoPagoScreen.Index');
    }*/


  return (
    <View style={[tw`flex-row w-full pr-5 pl-5 pb-6 pt-6 justify-center items-center shadow-lg bg-white`]}>
      <Pressable 
        style={[tw`flex-1 py-3 mx-2 rounded-full`, isLoading ? tw`bg-encabezado` : tw`bg-primary`, !uriCompDom ? tw`bg-buttonInac` : tw``, styles.buttonFooter]} 
        onPress={goToMetodoPago}
        disabled={isLoading}
      >
        <Text style={[tw`text-center text-xl`, isLoading ? tw`text-datosText` : tw`text-white`]}>
          {isLoading ? 'Cargando...' : 'Continuar'}
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonFooter: {
    width: 150,
    height: 55,
  },
});

export default FooterAltaDocumentosCompDomi;