import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function CalendarioIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 27.66 30.14"
            {...props}
        >
            <Path
            d="M24.61 4.66h-.88c-.08 0-.15.02-.21.05V1.77C23.52.8 22.73 0 21.75 0s-1.77.79-1.77 1.77v2.9H7.77s-.09 0-.13.02V1.77C7.64.8 6.85 0 5.87 0S4.1.79 4.1 1.77v2.92a.566.566 0 0 0-.18-.03h-.88C1.36 4.66 0 6.03 0 7.7v19.39c0 1.68 1.37 3.04 3.04 3.04h21.57c1.68 0 3.04-1.37 3.04-3.04V7.7c0-1.68-1.37-3.04-3.04-3.04Zm.67 21.3c0 .99-.81 1.8-1.8 1.8H4.27c-.99 0-1.8-.81-1.8-1.8V13.09c0-.29.23-.52.52-.52h21.77c.29 0 .52.23.52.52v12.87Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )   
}