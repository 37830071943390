import React, { useState, useEffect } from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image, ScrollView, SafeAreaView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux';
import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';

import HeaderFirma from './HeaderFirma';
import DocFirmas from './DocFirmas';
import FooterFirma from './FooterFirmas';

import { verificaSignDocument, setFirmaFirmada } from '../../store/slices/firmas';
import { setContrato } from '../../store/slices/contratos';

export default function ListFirmasScreen({navigation}) {
  const [visibleSign, setVisibleSign] = useState(false);
  const [urlDocumento, setUrlDocumento] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const dispatch = useDispatch();
  const Contrato = useSelector( state => state.contratos );
  const Firmas = useSelector( state => state.firmas );
  useEffect(() => {
    let firmasDataState = Firmas?.firmas
    const updateFirmaFirmado = (document_id:string) => { 
      firmasDataState = firmasDataState.map(firmaDoc => {
          if (firmaDoc.document_id === document_id && firmaDoc?.firmado !== "true") {
            return { ...firmaDoc, "firmado": "true" };
          }
          return firmaDoc
      })

      dispatch(setFirmaFirmada({firmas: firmasDataState}));
  }
    
    const validaSiFirmado = async (document_id, numeroContrato, tipoDocumento, urlLinkDoctoSing) =>{
      const contratosData = await dispatch(verificaSignDocument(document_id, numeroContrato, tipoDocumento)).then((result) => {
          if(result.firmado === true){
              tipoDocumento = tipoDocumento.replace(/_/g, ' ');
              const dataContrato = Contrato.contratos
              if(dataContrato?.numeroContrato?.trim() === numeroContrato.trim()){
                  let documentosData = dataContrato.documentos;
                  let updatedDocumentos = documentosData.map(documento => {
                      if (documento.nombre.trim() === tipoDocumento.trim()) {
                          return { ...documento, firmado: "true", urlDocumentoFirmado: result.urlDocumentoFirmado };
                      }
                      return documento;
                  });
                  const NewContrato = {...dataContrato, documentos:updatedDocumentos};
                  dispatch(setContrato({contratos: NewContrato}));
                  updateFirmaFirmado(document_id);
              }
            
          }else{
              // console.log("Este documento aun no se firma")
              setUrlDocumento(urlLinkDoctoSing);
              setVisibleSign(true);
              //return {"Success": false}
          }
          //console.log("ContratosDATA : ", contratosData);
          //return contratosData
        }).catch((error) => {
            console.log("Error : ",error);
        });
    }

    if(Firmas.firmas.length > 0){
      let updatedDocumentos = Firmas.firmas.map(documento_firma => {
        validaSiFirmado(documento_firma.document_id, documento_firma?.numero_contrato.trim(), documento_firma.tipo_documento, documento_firma.url);
      });
    }

  }, []);

  return (
      <View style={tw`bg-bgGrey flex-1`}>
        <HeaderFirma navigation={navigation} />
        <DocFirmas navigation={navigation} Contrato={Contrato.contratos} Firmas={Firmas} />
        
      </View>
  );
}