import React from 'react';
import {View, Image} from 'react-native'
// Configuración twrnc
import tw from '../../styles/twrnc-config';
import { LinearGradient } from 'expo-linear-gradient';
import TelecableLogo from '../../assets/icons/telecableLogo';
import { SvgUri } from 'react-native-svg';

export default function HeaderInicio({navigation, logoEmpresa}) {
    return(
        <View style={[tw`w-full shadow-lg items-center justify-center`, { height: 350, borderBottomLeftRadius:50, borderBottomRightRadius:50 }]}>
            <LinearGradient
                colors={['#E6E6E6', '#FFFFFF', '#FFFFFF']} // Colores del degradado
                style={[tw`absolute inset-0 items-center justify-center shadow-lg`, {borderBottomLeftRadius: 50, borderBottomRightRadius: 50}]}
                start={{ x: 0.5, y: 0 }} // Punto de inicio del degradado (arriba)
                end={{ x: 0.5, y: 1 }}   // Punto de finalización del degradado (abajo)
                />
            <View>
                {/* <TelecableLogo  fill={'white'}  width={350} height={200}/> */}
                <Image source={{ uri: logoEmpresa }} style={{ width: 400, height: 250, resizeMode: 'contain' }} />
            </View>
        </View>
    )
}