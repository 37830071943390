import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function TarjetaIcon(props) {
    return(
        <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_2"
    data-name="Capa 2"
    viewBox="0 0 84.68 53.9"
    {...props}
  >
    <Defs>
      <LinearGradient
        id="Degradado_sin_nombre_117"
        x1={42.34}
        x2={42.34}
        y1={8.1}
        y2={47.75}
        data-name="Degradado sin nombre 117"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#5c89b6" />
        <Stop offset={1} stopColor="#3e5c92" />
      </LinearGradient>
    </Defs>
    <G id="Capa_1-2" data-name="Capa 1">
      <Rect
        width={84.68}
        height={53.9}
        rx={8.8}
        ry={8.8}
        style={{
          fill: "url(#Degradado_sin_nombre_117)",
        }}
      />
      <Rect
        width={16.3}
        height={12.08}
        x={59.77}
        y={20.42}
        rx={2.64}
        ry={2.64}
        style={{
          fill: "#eadd6a",
        }}
      />
      <Rect
        width={39.41}
        height={5.4}
        x={8.62}
        y={39.98}
        className="cls-4"
        rx={2.7}
        ry={2.7}
      />
      <Rect
        width={17.01}
        height={5.4}
        x={50.91}
        y={39.98}
        className="cls-4"
        rx={2.7}
        ry={2.7}
      />
      <Path
        d="M20.92 8.73a6.205 6.205 0 0 0-4.69-2.14 6.23 6.23 0 0 0 0 12.46c1.87 0 3.55-.83 4.69-2.14a6.2 6.2 0 0 1 0-8.18Z"
        style={{
          fill: "#abc0d7",
          opacity: 0.5,
        }}
      />
      <G
        style={{
          opacity: 0.5,
        }}
      >
        <Path
          d="M25.61 6.59c-1.87 0-3.55.83-4.69 2.14.96 1.1 1.54 2.52 1.54 4.09s-.58 3-1.54 4.09a6.205 6.205 0 0 0 4.69 2.14 6.23 6.23 0 0 0 0-12.46Z"
          style={{
            fill: "#abc0d7",
          }}
        />
      </G>
      <Path
        d="M20.92 8.73c-.96 1.1-1.54 2.52-1.54 4.09s.58 3 1.54 4.09a6.2 6.2 0 0 0 0-8.18Z"
        style={{
          fill: "#d5dfeb",
        }}
      />
    </G>
  </Svg>
    )
}