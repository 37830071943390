import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function SucursalIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 16.35 27.2"
            {...props}
        >
            <Path
            d="M8.17 0C3.67 0 0 3.67 0 8.17c0 .65.08 1.3.23 1.93.22 1.33.52 2.4.89 3.18.8 1.69 4.18 7.26 5.74 9.79-2.1.27-3.07 1.17-3.07 2.02 0 1.01 1.37 2.1 4.39 2.1s4.39-1.09 4.39-2.1c0-.85-.97-1.75-3.07-2.02 1.55-2.54 4.93-8.11 5.74-9.79.37-.78.67-1.85.89-3.18.15-.63.23-1.28.23-1.93C16.35 3.67 12.68 0 8.17 0Zm3.12 25.1c-.09.24-1.14.84-3.12.84s-3.06-.61-3.13-.83c.06-.21.92-.72 2.55-.83.02.03.03.05.04.07.12.19.32.3.54.3s.42-.11.54-.3c0-.01.02-.04.04-.07 1.61.1 2.47.6 2.54.82ZM8.17 11.06c-1.72 0-3.12-1.4-3.12-3.12s1.4-3.12 3.12-3.12 3.12 1.4 3.12 3.12-1.4 3.12-3.12 3.12Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )   
}