import api from "../../utils/api";

const types = {
  STATUS_UPDATED: 'STATUS_UPDATED',
};



export function updateStatus(){
  return (dispatch, getState) => {
    api.get(`/status`)
      .then(({ data }) => {
        dispatch(statusUpdated(data))
      })
      .catch(error => { })
  }
}

export function statusUpdated(status){
  return { type: types.STATUS_UPDATED, status }
}


const initialState = {
  // definir
};



export const reducer = (state = initialState, action) => {
  const {type, user} = action;

  switch (type) {
    case types.STATUS_UPDATED:
      return Object.assign({}, state, {
        ...action.status,
      });

    default:
      return state;
  }
};
  