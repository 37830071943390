import { createSlice } from "@reduxjs/toolkit";

export const contratosSlice = createSlice({
    name: 'contratos',
    initialState: {
      contratos: [],
      statusContrato: "no_iniciado",
      isLoading: false,
      errorMessage: ""
    },
    reducers: {
        startGetContrato: (state) => {
            state.isLoading = true;
            state.statusContrato = "recuperando";
        },
        setContrato: (state, action) => {
            state.isLoading = false
            state.contratos = action.payload.contratos;
            state.statusContrato = "recuperado";
            state.errorMessage="";
        },
        errorContrato: (state, action) => {
            state.isLoading = false;
            state.statusContrato = "no_recuperado";
            state.contratos = [];
            state.errorMessage=action.payload.errorMessage;
        },
        getContratos: ( state, action ) => {
            state.isLoading = false;
            state.contratos = action.payload.contratos;
            state.statusContrato = "recuperando";
        },
        endRecoverContratos: (state, /* action */) => {
            state.isLoading = false
        }
    },
  });
  
  export const { startGetContrato, setContrato, errorContrato, getContratos, endRecoverContratos } = contratosSlice.actions;

  export default contratosSlice.reducer;
  