import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function FrimaIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 51.13 52.6"
            {...props}
        >
            <G data-name="Capa 1">
            <Path
                d="m11.39 48.37 10.93-10.88-8.86-8.34L2.49 39.88l8.9 8.49z"
                style={{
                fill: "#fcee21",
                }}
            />
            <Path d="M35.86 50.6H14.2c-.55 0-1 .45-1 1s.45 1 1 1h21.66c1.35 0 2.16-.5 2.6-.92.6-.56.93-1.33.93-2.17 0-1.64-1.32-3.34-3.53-3.34h-8.6c-1.04 0-1.52-.41-1.52-.69 0-.13.1-.28.28-.41.19-.14.64-.37 1.5-.37h9.9c1.64 0 2.52-1.21 2.52-2.35 0-.65-.27-1.28-.74-1.73-.35-.34-.99-.74-2-.74-.55 0-1 .45-1 1s.45 1 1 1c.27 0 .49.07.62.19.11.1.12.22.12.28 0 .23-.18.35-.52.35h-9.9c-1.07 0-2 .26-2.67.75-.7.51-1.1 1.25-1.1 2.03 0 1.33 1.21 2.69 3.52 2.69h8.6c1.12 0 1.53.8 1.53 1.34 0 .2-.05.49-.3.72-.26.25-.69.38-1.23.38ZM1.12 51.12l10.15-1.24c.08-.01.16-.03.24-.06h.02c.11-.05.2-.11.29-.19.01 0 .02-.01.03-.02l11.29-11.29 18.35-18.35 1.18-1.18.63.63-16.62 16.62a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29l17.32-17.32a.996.996 0 0 0 0-1.41l-1.34-1.34a2.735 2.735 0 0 0-.03-3.81s-.23-.24-.23-.24l6.07-6.07a4.249 4.249 0 0 0 0-6.01C49.09.45 48.02 0 46.88 0s-2.2.44-3 1.25l-6.07 6.07-.23-.23a2.728 2.728 0 0 0-3.85 0l-2.56 2.56-18.35 18.34L1.53 39.27s-.01.02-.02.03a.91.91 0 0 0-.19.29v.02c-.03.08-.05.16-.06.24L0 50.01c-.04.31.07.61.29.83.19.19.44.29.71.29h.12Zm1.33-4.62 2.17 2.17-2.48.3.3-2.48Zm4.69 1.87-4.39-4.39.23-1.85 6.01 6.01-1.85.23Zm28-39.88c.27-.27.75-.27 1.02 0l.94.94 4.6 4.6.94.94s.07.1.1.15c.02.03.05.06.06.09.11.26.05.57-.16.78l-1.85 1.85-7.5-7.5 1.85-1.85Zm10.15-5.83c.85-.85 2.33-.85 3.18 0s.88 2.3 0 3.18l-6.07 6.07-.45-.45 3.47-3.47a.996.996 0 1 0-1.41-1.41l-3.47 3.47-1.32-1.32 6.07-6.07Zm-13.41 9.1 7.5 7.5L22.44 36.2l-5.45-5.45 3.66-3.66a.996.996 0 1 0-1.41-1.41l-3.66 3.66-.64-.64 16.94-16.94ZM13.53 30.11l.64.64-2.42 2.42a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29l2.42-2.42 5.45 5.45-9.87 9.87-7.5-7.5 9.87-9.87Z" />
            </G>
        </Svg>
    )   
}