/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import React, { useEffect, useState } from "react";
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ColorSchemeName, Pressable } from 'react-native';

import tw from "../styles/tailwind";



import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';

// 
import { useSelector, useDispatch } from "react-redux";
//import { updateStatus } from "../store/Reducers/status";


/**
 * Import de las pantallas
 */
import LoginScreen from '../screens/Auth/LoginScreen'

import ExamplesScreen from '../screens/Examples/ExamplesScreen'
import SellDetailScreen from '../screens/Examples/DetailScreen'
import ExamplesStylesScreen from '../screens/Examples/StylesScreen'
import FormExampleScreen from "../screens/Examples/FormScreen";
import ReactQueryScreen from "../screens/Examples/ReactQueryScreen";

/* PANTALLAS PARA CONTRATOS */
import InicioScreen from "../screens/Inicio/Index";
import DatosPersonalesScreen from "../screens/Datos-personales/Index";

import ListFirmasScreen from "../screens/Firmas/Index";
import AltaDocumentosScreen from "../screens/AltaDocumentosINE/Index";
import AltaDocumentosCompDomicilioScreen from "../screens/AltaDocumentosCompDomi/Index";
import MetodoPagoScreen from "../screens/MetodoPago/Index";
import MetodoPagoEfectivoScreen from "../screens/PagoEfectivo/Index";
import CompletaPagoEfectivoScreen from "../screens/CompletaPagoEfectivo/Index";

import ValidarPagoScreen from "../screens/ValidarPago/Index";
// import PagoTarjetaScreen from "../screens/PagoTarjeta/Index";
import PagoExitoso from "../screens/ValidarPago/PagoExitoso";
import PagoError from "../screens/ValidarPago/PagoError";

import FirmarDocumentosScreen from "../screens/FirmarDocumentos/Index";

import NotFoundScreen from '../screens/NotFoundScreen';

import FullPDF from '../screens/FirmarDocumentos/FullPDF';
import PagoOxxoScreen from '../screens/PagoOxxo/Index';
import PagoSucursalScreen from '../screens/PagoSucursal/Index';

// Pantallas que vienen con Expo de TabNavigations
import ModalScreen from '../screens/Examples/TabNavigation/ModalScreen';
import TabOneScreen from '../screens/Examples/TabNavigation/TabOneScreen';
import TabTwoScreen from '../screens/Examples/TabNavigation/TabTwoScreen';


const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    // primary: 'rgb(255, 45, 85)',
    // background: theme.BACKGROUND_COLOR,
    border: "transparent",
  },
};



export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      //
      // si se quiere usar tema oscuro hay que descomentar esto
      //
      // theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
      >
      {/*
        Este se puede intercambiar a el RootTabNavigator que 
        trae la app demo de Expo
      */}
      <RootNavigator />
      {/* <RootTabNavigator /> */}
    </NavigationContainer>
  );
}



/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();



function RootNavigator() {
  const auth = useSelector((state) => state.auth);
  const status = useSelector((state) => state.status);
  const dispatch = useDispatch()

  // Despachamos la accion para actualizar el status de la app
  /*useEffect(() => {
    dispatch(updateStatus())
  }, [auth.isLogged]);*/

  return (
    <Stack.Navigator
      screenOptions={{
        // Ejemplo de customizacion del header
        headerStyle: {
          backgroundColor: tw.color('primary'),
        },
        headerTintColor: tw.color('secondary'),
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerShown: false, // si se quiere escondido
      }}
    >
      {!auth.isLogged ? (
        <>
          {/* 
            Rutas protegidas
          */}

          {/* La primer pantalla es el index */}
          <Stack.Screen
            name="InicioScreen.Index"
            component={InicioScreen}
            options={{ title: "Pantalla Principal" }}
          />

          {/* Pantalla de listado de documentos para firma */}
          <Stack.Screen
            name="DatosPersonalesScreen.Index"
            component={DatosPersonalesScreen}
            options={{ title: "Datos Personales" }}
          />

          {/* Pantalla con los Datos Personales */}
          <Stack.Screen
            name="ListFirmasScreen.Index"
            component={ListFirmasScreen}
            options={{ title: "Listado Documentos para Firmar" }}
          />

          {/* Pantalla de listado de captura INE */}
          <Stack.Screen
            name="AltaDocumentosScreen.Index"
            component={AltaDocumentosScreen}
            options={{ title: "Alta Documento INE" }}
          />

          {/* Pantalla de listado de captura Comprobante de Domicilio */}
          <Stack.Screen
            name="AltaDocumentosCompDomicilioScreen.Index"
            component={AltaDocumentosCompDomicilioScreen}
            options={{ title: "Alta Documento Comprobante de Domicilio" }}
          />

          {/* Pantalla de Metodos de Pago */}
          <Stack.Screen
            name="MetodoPagoScreen.Index"
            component={MetodoPagoScreen}
            options={{ title: "Metodo de Pago" }}
          />

          {/* Pantalla de Metodos de Pago Efectivo */}
          <Stack.Screen
            name="MetodoPagoEfectivoScreen.Index"
            component={MetodoPagoEfectivoScreen}
            options={{ title: "Metodo de Pago Efectivo" }}
          />

          {/* Pantalla de Metodos de Pago Tarjeta
          <Stack.Screen
            name="PagoTarjetaScreen.Index"
            component={PagoTarjetaScreen}
            options={{ title: "Metodo de Pago Tarjeta" }}
          /> */}

          {/* Pantalla Completa Pago Efectivo */}
          <Stack.Screen
            name="CompletaPagoEfectivoScreen.Index"
            component={CompletaPagoEfectivoScreen}
            options={{ title: "Metodo de Pago Efectivo" }}
          />

          {/* Pantalla de Validación de Pago */}
          <Stack.Screen
            name="ValidarPagoScreen.Index"
            component={ValidarPagoScreen}
            options={{ title: "Validar Pago" }}
          />

          {/* Pantalla de Validación de Pago */}
          <Stack.Screen
            name="PagoExitoso.PagoExitoso"
            component={PagoExitoso}
            options={{ title: "Pago Exitoso" }}
          />

          {/* Pantalla de Validación de Pago */}
          <Stack.Screen
            name="PagoError.PagoError"
            component={PagoError}
            options={{ title: "Pago Rechazado" }}
          />

          {/* Pantalla Documentos*/}
          <Stack.Screen
            name="FirmarDocumentosScreen.Index"
            component={FirmarDocumentosScreen}
            options={{ title: "FirmarDocumentosScreen Screen" }}
          />

          {<Stack.Screen
            name="FullPDF"
            component={FullPDF}
            options={{ title: "FullPDF" }}
          />}

          <Stack.Screen
            name="PagoOxxoScreen.Index"
            component={PagoOxxoScreen}
            options={{ title: "PagoOxxoScreen Screen" }}
          />

          <Stack.Screen
            name="PagoSucursalScreen.Index"
            component={PagoSucursalScreen}
            options={{ title: "PagoSucursalScreen Screen" }}
          />


          <Stack.Screen
            name="Examples.Styles"
            component={ExamplesStylesScreen}
            options={{ title: "Ejemplo de Estilos" }}
          />

          <Stack.Screen
            name="Examples.Form"
            component={FormExampleScreen}
            options={{ title: "Ejemplo Formulario" }}
          />

          <Stack.Screen
            name="Examples.ReactQuery"
            component={ReactQueryScreen}
            options={{ title: "Ejemplo react-query" }}
          />

          <Stack.Screen
            name="Examples.Detail"
            component={SellDetailScreen}
            options={{ title: "Detalle venta" }}
          />

          {/* Acá se siguen agregando rutas ...*/}
        </>
      ) : (
        <>
          {/* El stack de Auth */}
          <Stack.Screen
            name="Auth.Login"
            component={LoginScreen}
            options={{ title: "Iniciar sesión" }}
          />
        </>
      )}


      {/* Aquí se agregan rutas que se acceden logueado o deslogueado */}


    </Stack.Navigator>
  )
}


function RootTabNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen name="Modal" component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}




/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
import Colors from '../screens/Examples/TabNavigation/constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="TabOne"
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
      }}>
      <BottomTab.Screen
        name="TabOne"
        component={TabOneScreen}
        options={({ navigation }: RootTabScreenProps<'TabOne'>) => ({
          title: 'Tab One',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
          headerRight: () => (
            <Pressable
              onPress={() => navigation.navigate('Modal')}
              style={({ pressed }) => ({
                opacity: pressed ? 0.5 : 1,
              })}>
              <FontAwesome
                name="info-circle"
                size={25}
                color={Colors[colorScheme].text}
                style={{ marginRight: 15 }}
              />
            </Pressable>
          ),
        })}
      />
      <BottomTab.Screen
        name="TabTwo"
        component={TabTwoScreen}
        options={{
          title: 'Tab Two',
          tabBarIcon: ({ color }) => <TabBarIcon name="code" color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
