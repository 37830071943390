import React, {useState} from 'react';
import {  StyleSheet, View, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import ModalFirmas from './ModalFirmas';
import { useRoute } from '@react-navigation/native';

/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';

const styles = StyleSheet.create({
    textOverlay: {
        top: '27%',
        left: '90%',
        transform: [{ translateX: -50 }, { translateY: -50 }],
        borderColor: "#000",
    },
    touchableOverlay: {
        top: 15,
        left: 10,
        borderColor: "#000",
        borderBottomRightRadius: 20,
    },
    textInforme: {
        top: 10,
        left: 15,
        width: "100%",
        fontSize: 16,
    },
    textQuestion: {
        top: 40,
        left: 20,
        fontSize: 16,
        textDecorationLine: "underline"
    }
});

export default function HeaderFirma({navigation}) {
    const [visible, setVisible] = useState(false);
    // const route = useRoute();
    // const { idEmpresa, codigoActivacion } = route.params;
    const goToDatosPersonales= () => {
        navigation.navigate('DatosPersonalesScreen.Index');
    }

    const openModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    };
 
    return (
        <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
        <View style={tw`flex-row `}>
            <Pressable style={tw`mr-1 pt-9`} onPress={() => goToDatosPersonales()}>
                <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
            </Pressable>
            <Text style={tw`text-primary text-3xl mt-8 font-500 `}>Firmas</Text>
        </View>
        <Text style={[tw`text-primaryText mt-3 text-xl pl-9`]}>Lee atentamente y firma los siguientes documentos para continuar</Text>
        <Pressable style={tw`mr-2 pt-4`} onPress={openModal}>
            <Text style={[tw`text-lg pl-9 underline text-primary`]} >¿Por qué me piden esta información?</Text>
        </Pressable>
        <ModalFirmas visible={visible} closeModal={closeModal} />
    </View>      
    );
  };

  
  