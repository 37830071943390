import { createSlice } from "@reduxjs/toolkit";

export const pagosDomicilioSlice = createSlice({
    name: 'pagosDomicilio',
    initialState: {
      statusPagoDomicilio: "no_enviado",
      isLoading: false,
      messagePagoDomicilio: "",
      errorMessage: ""
    },
    reducers: {
        startPagoDomicilio: (state) => {
            state.statusPagoDomicilio="inicio_envio";
            state.isLoading=true;
            state.messagePagoDomicilio="";
            state.errorMessage="";
        },
        setPagoDomicilioData: (state, action) => {
            state.statusPagoDomicilio=action.payload.statusPagoDomicilio;
            state.isLoading=false;
            state.messagePagoDomicilio=action.payload.messagePagoDomicilio;
            state.errorMessage=action.payload.errorMessage;
        },
        errorPagoDomicilio: (state, action) => {
            state.statusPagoDomicilio=action.payload.statusPagoDomicilio;
            state.isLoading=false;
            state.messagePagoDomicilio=action.payload.messagePagoDomicilio;
            state.errorMessage=action.payload.errorMessage;
        },
        endPagoDomicilio: (state) => {
            state.statusPagoDomicilio="enviado";
            state.isLoading=false;
            state.messagePagoDomicilio="";
            state.errorMessage="";
        },
    },
  });
  
  export const { startPagoDomicilio, setPagoDomicilioData, errorPagoDomicilio, endPagoDomicilio } = pagosDomicilioSlice.actions;

  export default pagosDomicilioSlice.reducer;