import * as React from "react"
import Svg, {Defs,
    LinearGradient,
    Stop,
    G,
    Path,
    Rect, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function PasaporteIcon(props) {
    return(
        <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 2"
        viewBox="0 0 89.46 107.47"
        {...props}
      >
        <Defs>
          <LinearGradient
            id="a"
            x1={102.51}
            x2={21.6}
            y1={-12.24}
            y2={76.37}
            data-name="Degradado sin nombre 18"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset={0.31} stopColor="#4d6d55" />
            <Stop offset={0.88} stopColor="#35593e" />
          </LinearGradient>
        </Defs>
        <G data-name="Capa 1">
          <Path
            d="M0 0h80.66c4.85 0 8.8 3.94 8.8 8.8v89.88c0 4.85-3.94 8.8-8.8 8.8H0V0Z"
            style={{
              fill: "url(#a)",
            }}
          />
          <Path
            d="M44.73 37.38c-12.17 0-22.06 9.9-22.06 22.06s9.9 22.06 22.06 22.06 22.06-9.9 22.06-22.06-9.9-22.06-22.06-22.06Zm9.3 26.67c.13-1.52.2-3.07.2-4.61s-.07-3.09-.2-4.61c5.72 1.2 8.76 3.32 8.76 4.61s-3.04 3.42-8.76 4.61Zm-9.3 13.45c-1.29 0-3.42-3.04-4.61-8.76 1.52.13 3.07.2 4.61.2s3.09-.07 4.61-.2c-1.2 5.72-3.32 8.76-4.61 8.76Zm0-12.56c-1.89 0-3.62-.1-5.23-.27-.17-1.61-.27-3.34-.27-5.23s.1-3.62.27-5.23c1.61-.17 3.34-.27 5.23-.27s3.62.1 5.23.27c.17 1.61.27 3.34.27 5.23s-.1 3.62-.27 5.23c-1.61.17-3.34.27-5.23.27Zm-18.06-5.5c0-1.29 3.04-3.42 8.76-4.61-.13 1.52-.2 3.07-.2 4.61s.07 3.09.2 4.61c-5.72-1.2-8.76-3.32-8.76-4.61Zm18.06-18.06c1.29 0 3.42 3.04 4.61 8.76-1.52-.13-3.07-.2-4.61-.2s-3.09.07-4.61.2c1.2-5.72 3.32-8.76 4.61-8.76Zm16.9 11.74c-2.24-1.1-5.04-1.92-8.12-2.46-.53-3.08-1.35-5.89-2.46-8.12a18.11 18.11 0 0 1 10.58 10.58ZM38.41 42.54c-1.1 2.24-1.92 5.05-2.46 8.12-3.08.53-5.89 1.35-8.12 2.46a18.11 18.11 0 0 1 10.58-10.58ZM27.83 65.76c2.24 1.1 5.04 1.92 8.12 2.46.53 3.08 1.35 5.89 2.46 8.12a18.11 18.11 0 0 1-10.58-10.58Zm23.22 10.58c1.1-2.24 1.92-5.05 2.46-8.12 3.08-.53 5.89-1.35 8.12-2.46a18.11 18.11 0 0 1-10.58 10.58Zm25.92-53c0 2.3-1.86 4.16-4.16 4.16H16.75c-2.3 0-4.16-1.86-4.16-4.16s1.86-4.16 4.16-4.16h56.06c2.3 0 4.16 1.86 4.16 4.16Z"
            style={{
              fill: "#fcee21",
            }}
          />
        </G>
      </Svg>
    )   
}