import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function CorreoIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 27.75 25.25"
            {...props}
        >
            <Path
            d="M23.25 0H4.49A4.49 4.49 0 0 0 0 4.49v16.26a4.49 4.49 0 0 0 4.49 4.49h18.76a4.49 4.49 0 0 0 4.49-4.49V4.49A4.49 4.49 0 0 0 23.25 0Zm.23 8.8-8.98 5.3c-.16.09-.33.14-.51.14s-.34-.04-.5-.13l-9.22-5.3c-.48-.27-.64-.89-.37-1.37.27-.48.89-.64 1.37-.37l8.72 5.01 8.48-5c.48-.28 1.09-.12 1.37.35.28.48.12 1.09-.35 1.37Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )   
}