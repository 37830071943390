import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function ContratoIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 20.18 24.52"
            {...props}
        >
            <Path
            d="M18.25 0H1.93C.87 0 0 .87 0 1.93v20.65c0 1.07.87 1.93 1.93 1.93H14.4c.31 0 .57-.17.66-.25l4.9-4.62c.18-.14.22-.5.22-.6V1.93C20.18.86 19.31 0 18.25 0ZM3.64 6.12c0-.56.46-1.02 1.02-1.02h10.86c.56 0 1.02.46 1.02 1.02s-.46 1.02-1.02 1.02H4.66c-.56 0-1.02-.46-1.02-1.02Zm0 3.36c0-.56.46-1.02 1.02-1.02h10.86c.56 0 1.02.46 1.02 1.02s-.46 1.02-1.02 1.02H4.66c-.56 0-1.02-.46-1.02-1.02Zm1.02 4.38c-.56 0-1.02-.46-1.02-1.02s.46-1.02 1.02-1.02h10.86c.56 0 1.02.46 1.02 1.02s-.46 1.02-1.02 1.02H4.66Zm9.55 9.21v-2.51c0-1.04.84-1.88 1.88-1.88h2.77l-4.65 4.39Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )   
}