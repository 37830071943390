import React, {useState} from 'react';
import { View, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import { useRoute } from '@react-navigation/native';
import Text from '../../components/Text';
import ModalAyuda from './ModalAyuda';
export default function FooterDatos({ navigation }) {
    // const route = useRoute();
    // const { idEmpresa, codigoActivacion } = route.params;
    const [visible, setVisible] = useState(false);
    function goToFirmas() {
        navigation.navigate('ListFirmasScreen.Index');
    }
    const openModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    };

    return (
        <View style={tw`flex-row mt-4 p-3 justify-center items-center mb-4`}>
            <Pressable style={tw`mx-2 bg-white py-4 rounded-full border border-primary flex-1`} onPress={openModal}>
                <Text style={tw`text-center text-primary text-xl`}>Ayuda</Text>
            </Pressable> 
            <Pressable style={tw`mx-2 bg-primary py-4 rounded-full flex-2`} onPress={() => goToFirmas()}>
                <Text style={tw`text-center text-white text-xl`}>Continuar</Text>
            </Pressable>
            <ModalAyuda visible={visible} closeModal={closeModal} />
        </View>
    );
};
