
import { createSlice } from '@reduxjs/toolkit';

export const tokenSlice = createSlice({
  name: 'token',
  initialState: {
    t: ''
  },
  reducers: {
    setToken: (state, action) => {
      state.t = action.payload;
    },
    clearToken: (state) => {
      state.t = '';
    }
  }
});

export const { setToken, clearToken } = tokenSlice.actions;
export default tokenSlice.reducer;
