import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function PaqueteIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 25.81 23.28"
            {...props}
        >
            <Path
            d="M22.9 4.12h-3.66V2.95c0-1.63-1.52-2.95-3.39-2.95H9.96C8.09 0 6.57 1.32 6.57 2.95v1.17H2.91C1.3 4.12 0 5.43 0 7.03v13.34c0 1.61 1.31 2.91 2.91 2.91H22.9c1.61 0 2.91-1.31 2.91-2.91V7.03c0-1.61-1.31-2.91-2.91-2.91ZM9.45 3.18c0-.46.55-.86 1.19-.86h4.52c.65 0 1.19.39 1.19.86v1.03H9.44V3.18ZM21.1 13.2h-5.45v1.32c0 .71-.57 1.28-1.28 1.28h-3.63c-.71 0-1.28-.57-1.28-1.28V13.2H4.25v-1h5.21v-1.32c0-.71.57-1.28 1.28-1.28h3.63c.71 0 1.28.57 1.28 1.28v1.32h5.45v1Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )   
}