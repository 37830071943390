import { createSlice } from '@reduxjs/toolkit';

export const sucursalesSlice = createSlice({
  name: 'sucursales',
  initialState: {
    sucursalesData: [],
    status: 'no_busqueda',
    error: ''
  },
  reducers: {
    clearSucursales: (state) => {
      state.sucursalesData = [];
      state.status = 'no_busqueda',
      state.error = '';
    },
    setSucursales: (state, action) => {
      state.sucursalesData = action.payload.sucursales;
      state.status = 'encontrados',
      state.error = '';
    }, 
    errorSucursales: (state, action) => {
      state.sucursalesData = [];
      state.status = 'error_busqueda',
      state.error = action.payload.errorMessage;
    }
  }
});

export const { clearSucursales, setSucursales, errorSucursales } = sucursalesSlice.actions;
export default sucursalesSlice.reducer;
