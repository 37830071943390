import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

const FooterMetodoPago = ({ navigation, selectedPago }) => {
  // const route = useRoute();
  // const { idEmpresa, codigoActivacion } = route.params;
  const Contrato = useSelector(state => state.contratos);
  const urlPagoTarjeta = Contrato.contratos.url_pago_tarjeta;

  const goToNextPay = () => {
    //console.log("INICIA PAGO");
    if (selectedPago === "Efectivo") {
      ReactGA.event({
        category: 'Metodo-Pago',
        action: 'Pago-Efectivo',
        label: 'Pago-Efectivo',
      });
      navigation.navigate('MetodoPagoEfectivoScreen.Index');
    } else if (selectedPago === "Tarjeta") {
      ReactGA.event({
        category: 'Metodo-Pago',
        action: 'Pago-Tarjeta',
        label: 'Pago-Tarjeta',
      });
      window.open(urlPagoTarjeta, '_blank');
    } else if (selectedPago === "Oxxo") {
      ReactGA.event({
        category: 'Metodo-Pago',
        action: 'Pago-Oxxo',
        label: 'Pago-Oxxo',
      });
      navigation.navigate('PagoOxxoScreen.Index');
    } else if (selectedPago === "Sucursal") {
      ReactGA.event({
        category: 'Metodo-Pago',
        action: 'Pago-Sucursal',
        label: 'Pago-Sucursal',
      });
      navigation.navigate('PagoSucursalScreen.Index');
    }
  }

  return (
    <View style={[tw`flex-row w-full pr-5 pl-5 pb-6 pt-6 mt-6 justify-center items-center shadow-lg bg-white`]}>
      {selectedPago !== "" ? (
        <Pressable style={[tw`flex-1 py-3 mx-2 bg-primary rounded-full`, styles.buttonFooter]} onPress={goToNextPay}>
          <Text style={[tw`text-center text-white text-xl`]}>Continuar</Text>
        </Pressable>
      ) : (
        <Pressable style={[tw`flex-1 py-3 mx-2 bg-buttonInac rounded-full`, styles.buttonFooter]} onPress={goToNextPay}>
          <Text style={[tw`text-center text-textButton text-xl`]}>Continuar</Text>
        </Pressable>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonFooter: {
    width: 150,
    height: 55,
  },
});

export default FooterMetodoPago;
