import React from "react";
import { Button, StyleSheet, Text, TextInput, View } from "react-native";
import { StatusBar } from "expo-status-bar";
import { useSelector, useDispatch } from 'react-redux';
import {actions} from "../../store/Reducers/auth";


export interface IAppProps {}

export default function LoginScreen(props: IAppProps) {
  const dispatch = useDispatch()
  

  return (
    
      <View style={styles.container} >
        <StatusBar style="auto" />
        <TextInput style={styles.input} placeholder="Correo Electrónico"/>
        <TextInput
          style={styles.input}
          placeholder="Contraseña"
          secureTextEntry
        />
        <Button title={"Iniciar sesión"} onPress={() => {
          console.log("click");
          
          dispatch(actions.login({id: 123, name: "paco"}, "new token"))
        }}/>
      </View>
  );
}


/*function Component(){}
const Component = () => {}
const Component = function(){}*/


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    paddingVertical: 15,
    paddingHorizontal: 45,
    marginBottom: 25,
    borderBottomColor: "#888",
    borderBottomWidth: 1,
  },
});
