import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function FullScreenIcon(props) {
    return(
        <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        data-name="Capa 2"
        viewBox="0 0 27.81 26.87"
        {...props}
      >
        <Defs></Defs>
        <G id="Capa_1-2" data-name="Capa 1">
          <Path
            d="M18.92 9.02c.2.2.45.29.71.29s.51-.1.71-.29l5.42-5.42v4.65a1.003 1.003 0 0 0 1.71.71c.18-.18.29-.43.29-.71V2.01c0-.54-.21-1.04-.59-1.43-.39-.37-.9-.58-1.44-.58h-6.1c-.55 0-1 .45-1 1s.45 1 1 1h4.88l-5.59 5.6a.996.996 0 0 0 0 1.41ZM27.81 18.75c0-.55-.45-1-1-1s-1 .45-1 1v4.88l-5.6-5.59a.996.996 0 1 0-1.41 1.41l5.42 5.42h-4.65a1.003 1.003 0 0 0-.71 1.71c.18.18.43.29.71.29h6.24c.54 0 1.04-.21 1.43-.59.38-.38.59-.89.59-1.42v-6.1ZM8.9 17.85a.996.996 0 0 0-1.41 0l-5.42 5.42v-4.65a1.003 1.003 0 0 0-1.71-.71c-.18.18-.29.43-.29.71v6.24c0 .54.21 1.04.59 1.43.38.38.89.59 1.42.59h6.1c.55 0 1-.45 1-1s-.45-1-1-1H3.31l5.59-5.6a.996.996 0 0 0 0-1.41ZM3.6 2h4.65A1.003 1.003 0 0 0 8.96.29.997.997 0 0 0 8.25 0H2.01C1.48 0 .97.21.59.59.21.97 0 1.48 0 2.01v6.1c0 .55.45 1 1 1s1-.45 1-1V3.24l5.6 5.59c.2.2.45.29.71.29s.51-.1.71-.29a.996.996 0 0 0 0-1.41L3.6 2Z"
            className="cls-1"
          />
        </G>
      </Svg>
    )   
}