import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function CalendarioIcon(props) {
    return(
        <Svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 2"
    viewBox="0 0 19.37 28.79"
    {...props}
  >
    <Path
      d="M17.91 4.64C16.22 1.86 13.42.18 10.22.01c-.35-.02-.71-.02-1.07 0-3.2.17-6 1.85-7.69 4.63a10.01 10.01 0 0 0-.56 9.34l8.38 14.56a.438.438 0 0 0 .8 0l8.38-14.56a9.989 9.989 0 0 0-.56-9.33ZM9.68 15.05c-2.93 0-5.32-2.38-5.32-5.32s2.38-5.32 5.32-5.32 5.31 2.38 5.31 5.32-2.38 5.32-5.31 5.32Z"
      data-name="Capa 1"
      fill= {props.fill}
    />
  </Svg>
    )   
}