import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Modal, Button, ScrollView, Pressable, Platform } from 'react-native';
import SignatureCanvas from 'react-signature-canvas';
import { useDispatch, useSelector } from 'react-redux';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';

import HeaderFirmarDocs from './HeaderFirmarDoc';
import FormaFirmarDocs from './FormaFirmarDoc';
import FooterFirmarDocs from './FooterFirmarDoc';
import { setContrato } from '../../store/slices/contratos';

export default function FirmarDocumentosScreen({navigation}) {
  const Contrato = useSelector( state => state.contratos );
  const docFirmar = Contrato.contratos.documentos;
  /**
   * Ejemplo de petición a una API
   */
  function useTheAPI(folio: String, nombre_cliente: String){
    const data = { folio, nombre_cliente }

    api.post("/sells", data)
      .then((response) => {
        // OK!
        // console.log("Venta creada!", response);
      })
      .catch(error => {
        // Error
        // console.log("Error al crear venta", error)
      })
  }
  return (
    <View style={tw`bg-bgGrey `}>
      <HeaderFirmarDocs navigation={navigation} />
      <FormaFirmarDocs navigation={navigation} docFirmar={docFirmar} />
    </View>
  );
};

