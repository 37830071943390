import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { WorkSans_600SemiBold } from '@expo-google-fonts/work-sans';
import ModalFirmas from './ModalFirmas';
import * as Font from 'expo-font';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
/* Image Picker elemento */
import * as ImagePicker from 'expo-image-picker';
import axios from 'axios';
import { uploadFile } from '../../services/General';
import { useSelector, useDispatch } from "react-redux";
import { subeImagenComprobanteDomicilio } from '../../store/slices/imagenes'
import { setDocumentoURL } from '../../store/slices/documentos'

import CheckFirmas from '../../assets/icons/check';
import DocumentoIcon from '../../assets/icons/documento';

import FooterAltaDocumentosCompDomi from './FooterAltaDocumentosCompDomi';

import {ModalCargaCompDom} from './ModalCargaCompDom'

export default function FormaAltaDocumentosCompDomi({navigation}) {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const Contrato = useSelector( state => state.contratos, );
    const ImagenesData = useSelector( state => state.imagenes );
    const id_empresa = Contrato.contratos.idEmpresa;
    const id_activacion = Contrato.contratos.codigoActivacion;
    const [imagenCompDomicilio, setImagenCompDomicilio] = useState(false);
    const [uriCompDom, setUriCompDom] = useState("");
    const [visibleCarga, setVisibleCarga] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locationCompDom, setLocationCompDom] = useState('');
    // console.log(id_empresa);
    /*useEffect(() => {
        if(ImagenesData!==undefined){
            setImagenCompDomicilio(ImagenesData.imagenComprobanteDomicilio);
        }
    }, []);*/

    useEffect(() => {
        if(ImagenesData!==undefined){
            setImagenCompDomicilio(ImagenesData.imagenComprobanteDomicilio);
        }

        if(Contrato.contratos?.imagenesIdentificacion && Contrato.contratos?.imagenesIdentificacion.length  > 0){
            Contrato.contratos.imagenesIdentificacion.map(element => {
                if(element.includes('comprobante_domicilio')){
                    if(uriCompDom !== "" && uriCompDom !== element){
                        setUriCompDom(uriCompDom);
                    }else{
                        setUriCompDom(element);
                    }
                }
            });
        }
    },[uriCompDom]);

    const tomarComprobante = async () => {
        /* Se piden los permisos para abrir la biblioteca de imagenes */
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        /* Se valida que el usuario de permiso o no */
        if(!permissionResult.granted){
            alert('Los permisos para acceder a tu cámara son necesarios');
            return;
        }
        
        /* Se guarda la foto tomada por el usuario */
        const Comprobante =  await ImagePicker.launchCameraAsync({
            allowsEditing: true, // Permite editar la imagen (recortar)
            quality: 1, // Calidad de la imagen
            base64: false,
        });
        if (!Comprobante.canceled) {
            /* URI DE LA IMAGEN TOMADA */
            const uriComprobante = Comprobante.assets[0].uri;
            setUriCompDom(uriComprobante);
            dispatch(setDocumentoURL({"newDocumento":{"tipo_documento":"comprobante_domicilio","uri":uriComprobante}}));
        } else {
            console.log("El usuario cancelo la toma de foto")
            return; 
        }
    }

    const openModalCarga = () => {
        console.log("Se esta cargando la imagen ")
        setVisibleCarga(true);
    };

    const closeModalCarga = () => {
        setVisibleCarga(false);
    };

    const subirImagen = async () => {
        // openModalCarga();
        /*setIsLoading(true);
        try {
            if(!uriCompDom.includes("telecable-images")){
                const respuestaUploadComprobanteDomicilio = await uploadFile(uriCompDom, id_empresa,  id_activacion, 'comprobante_domicilio');
                if(respuestaUploadComprobanteDomicilio.Success){
                    //console.log("***** agrega el comprobante de domicilio  a documentos url");
                    //console.log("add url");
                    setLocationCompDom(respuestaUploadComprobanteDomicilio.data.Location);
                    dispatch(setDocumentoURL({"newDocumento":respuestaUploadComprobanteDomicilio.data.Location}));
                    //dispatch( subeImagenComprobanteDomicilio(respuestaUploadComprobanteDomicilio.Success, respuestaUploadComprobanteDomicilio.data.Location) );
                    setImagenCompDomicilio(respuestaUploadComprobanteDomicilio.Success);
                    // closeModalCarga();
                    setIsLoading(false);
                    return true;
                } else {
                    alert(`Error al subir el archivo : ${respuestaUploadComprobanteDomicilio.Error}`);
                    // closeModalCarga();
                    setIsLoading(true);
                    return false;
                }
            }else{
                return true;
                setIsLoading(false);
            }
        } catch {
            alert(`Error al subir el archivo`);
            // closeModalCarga();
            setIsLoading(true);
            return false;
        }*/
    }

    return (
        <>
            <View style={tw`w-full bg-bgGrey flex-grow pl-7 pr-7 justify-between mt-10`}>
                <View style={[tw`bg-white p-6 rounded-3xl shadow-lg ml-2 mr-2`]}>
                <View style={[tw`flex-row justify-between`]} >
                    <Text style={[tw`text-xl text-primaryText mb-4 ml-1`]}>Comprobante domicilio</Text>
                    {uriCompDom ?
                    (<CheckFirmas fill={'#45ba47'} width={22} height={22} style={[tw`mb-5`]}/>):
                    <></>
                    }
                </View>
                    {
                        uriCompDom != "" ?
                        (
                            <View>
                                <Pressable style={[tw`rounded-lg items-center`]} onPress={tomarComprobante}>
                                    <Image source={{ uri: uriCompDom }} style={{ width: 250, height: 150, resizeMode: 'contain'}} />
                                </Pressable>
                            </View>
                        ):(
                            <View>
                                <Pressable style={[tw`border-2 border-gray-300 rounded-lg p-6 justify-center items-center`, {height:275}]} onPress={tomarComprobante}>
                                    <DocumentoIcon width={70} height={100}/>
                                    <Text style={[tw`text-datosText text-lg`]}>Toca para tomar foto</Text>
                                </Pressable>
                            </View>
                        )
                    }
                        
                </View>
                <ModalCargaCompDom visibleCarga={visibleCarga} closeModalCarga={closeModalCarga}></ModalCargaCompDom>
            </View>

            <FooterAltaDocumentosCompDomi navigation={navigation} uriCompDom={uriCompDom} subirImagen={subirImagen} isLoading={isLoading} locationCompDom={locationCompDom} />
        </>
        
    );
};
