import React, {useState, useEffect} from 'react';
import {  View, Pressable } from 'react-native';
import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import FooterMetodoPagoEfectivo from './FooterMetodoPagoEfectivo';

/* Iconos */
import InfoIcon from '../../assets/icons/info';


export default function FormaMetodoPagoEfectivo({navigation}) {
    const [valueDia, setValueDia] = useState('diaSig');
    const [valueHorario, setValueHorario] = useState('');
    let primaryColor = '#45BA47';

    const changeHoy = () => {
        setValueDia("Hoy");
    }
    const changeMañana = () => {
        setValueDia("diaSig");
    }


    return (
        <>
        <View style={tw`w-full flex-grow  mt-6 pl-7 pr-7`}>
            {/* <Text style={[tw`mt-3 text-lg text-primaryText `]}>Selecciona en qué momento podemos visitarte</Text> */}
            <Text style={[tw`mt-3 text-lg text-primaryText `]}>La visita se realizará el:</Text>
            <View>
                <View style={tw`mb-3 `}>
                    {/* {valueDia == "Hoy" ? 
                        (<>
                            <View>
                                <Pressable
                                    style={[tw`pt-5 pb-5 pl-3 pr-3 mr-2 mt-5 flex-1 border rounded-lg shadow-lg border-primary bg-tabs`]}
                                    onPress={changeHoy}
                                    >   
                                    <View style={tw`flex`}>
                                        <Text style={tw`text-xl text-primaryText font-500`}>Hoy mismo</Text>
                                        <Text style={tw`text-sm text-datosText`}>El horario de visita es de 09:00 a 18:00 y depende de la disponibilidad</Text>
                                    </View>
                        
                                </Pressable>
                            </View>
                        </>)
                        :
                        (<>
                             <View>
                                <Pressable
                                    style={[tw`pt-5 pb-5 pl-3 pr-3 mr-2 flex-1 rounded-xl border border-primary bg-white mt-5`]}
                                    onPress={changeHoy}
                                    >   
                                    <View style={tw`flex`}>
                                        <Text style={tw`text-xl text-primaryText font-500`}>Hoy mismo</Text>
                                        <Text style={tw`text-sm text-datosText`}>El horario de visita es de 09:00 a 18:00 y depende de la disponibilidad</Text>
                                    </View>
                        
                                </Pressable>
                            </View>
                        </>)

                    } */}
                    {/* {valueDia == "diaSig" ?  
                         (<>*/}
                            <View>
                                <Pressable
                                    style={[tw`pt-5 pb-5 pl-3 pr-3 mr-2 mt-5 flex-1 border rounded-lg shadow-lg border-primary bg-tabs`]}
                                    // onPress={changeMañana}
                                    >   
                                    <View style={tw`flex`}>
                                        <Text style={tw`text-xl text-primaryText font-500`}>Día siguiente</Text>
                                        <Text style={tw`text-sm text-datosText pb-4`}>Los días son de lunes a sábado con un horario de 09:00 a 18:00</Text>
                                    </View>
                        
                                </Pressable>
                            </View>
                        {/* </>)
                        :
                        (<>
                             <View>
                                <Pressable
                                    style={[tw`pt-5 pb-5 pl-3 pr-3 mr-2 flex-1 rounded-xl border border-primary bg-white mt-5`]}
                                    onPress={changeMañana}
                                    >   
                                    <View style={tw`flex`}>
                                    <Text style={tw`text-xl text-primaryText font-500`}>Día siguiente</Text>
                                    <Text style={tw`text-sm text-datosText pb-4`}>Los días son de lunes a sábado</Text>
                                    </View>
                        
                                </Pressable>
                            </View>
                        </>) */}

                    {/* } */}
                </View>
                <View style={tw`bg-tabs mt-8 rounded-lg p-3 items-center mb-4`}>
                        <InfoIcon heigh={32} width={32} fill={primaryColor} style={tw`mb-4 mt-2`} />
                        <Text style={tw`text-xl text-center text-datosText mb-6`}>
                            Nos comunicaremos al teléfono que proporcionaste para dar seguimiento a tu contratación.
                        </Text>
                </View>
                
            </View>
        </View>
            <FooterMetodoPagoEfectivo navigation={navigation} valueDia={valueDia}/>
        </>
    );
  };

  
  