import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import UbicacionIcon from '../../assets/icons/ubicacion';

import { useSelector, useDispatch } from "react-redux";

/* Iconos */
import { FontAwesome } from '@expo/vector-icons';

export default function FormaPagoSucursal({ navigation }) {
    const [center, setCenter] = useState({}); // Centro en Pachuca, Hidalgo
    const [selectedSucursal, setSelectedSucursal] = useState(null);
    const [sucursalesConCoordenadas, setSucursalesConCoordenadas] = useState([]);
    const Contrato = useSelector(state => state.contratos);
    const numeroContrato = Contrato.contratos.numeroContrato;
    const sucursales = Contrato.contratos.sucursales;
    // console.log(sucursales);

    const municipio = Contrato.contratos.municipio;
    // Función para obtener las coordenadas del municipio de forma dinámica
    const getMunicipioCoordinates = async (municipio: string) => {
        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${municipio}&key=AIzaSyB_VpXy3aKTYqrbUS24CftvJ6fGzNSTxKg`);
        const data = await response.json();
        
        if (data.results.length > 0) {
            const { lat, lng } = data.results[0].geometry.location;
            setCenter({ lat, lng });  // Centrar el mapa en las coordenadas del municipio
        }
    };
    // Función para obtener las coordenadas de una dirección
    const getCoordinates = async (address) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyB_VpXy3aKTYqrbUS24CftvJ6fGzNSTxKg`);
            const data = await response.json();
            
            if (data.results.length > 0) {
                return data.results[0].geometry.location;
            }
        } catch (error) {
            console.error('Error obteniendo coordenadas:', error);
        }
        return null;
    };

    // Geocodificar todas las sucursales
    const geocodeSucursales = async () => {
        const sucursalesPromises = sucursales.map(async (sucursal) => {
            const fullAddress = `${sucursal.CALLE} ${sucursal.EXTERIOR}, ${sucursal.COLONIA}, ${sucursal.MUNICIPIO}, ${sucursal.ESTADO}, ${sucursal.CP}`;
            const coordinates = await getCoordinates(fullAddress);
            if (coordinates) {
                // console.log(`Coordenadas de sucursal ${sucursal.NOMBRE}:`, coordinates);  // Imprimir coordenadas de cada sucursal
                return { ...sucursal, lat: coordinates.lat, lng: coordinates.lng };
            }
            return null;
        });

        const sucursalesConCoords = await Promise.all(sucursalesPromises);
        setSucursalesConCoordenadas(sucursalesConCoords.filter(suc => suc !== null));  // Filtrar sucursales válidas
    };
    
    // Llamar a la función cuando el componente se monte o cuando cambie el municipio
    useEffect(() => {
        getMunicipioCoordinates(municipio);
        geocodeSucursales();
    }, [municipio, sucursales]);


    // Cargar Google Maps API
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyB_VpXy3aKTYqrbUS24CftvJ6fGzNSTxKg' // Reemplaza con tu API Key
    });

    return (
        <View>
            <View style={tw`w-full flex-grow mt-15 mb-10 pl-7 pr-7`}>
                <Text style={tw`text-primaryText text-xl mb-2 text-center`}>Ha finalizado exitosamente su trámite</Text>
                <View style={[tw`mt-4`]}>
                    <Text style={tw`text-primaryText font-500 text-xl text-center mb-4 `}>Número de contrato</Text>
                    <Text style={tw`bg-tabs text-datosText text-2xl text-center py-2 rounded-lg tracking-widest`}>{numeroContrato}</Text>
                </View>
                <View style={[tw`mt-4`]}>
                    <Text style={tw`text-primaryText font-500 text-xl text-center mb-4 `}>Datos de Cobro</Text>
                    <Text style={tw`bg-tabs text-datosText text-xl text-center py-2 rounded-lg pr-15 pl-15 text-center`} multiline={true}>
                        Puede pasar a cualquiera de nuestras sucursales para realizar el pago con el número de contrato.
                    </Text>
                </View>
            </View>
            {/* Mapa de sucursales */}
            <View style={[tw`mt-8`]}>
                {isLoaded ? (
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '600px',
                        }}
                        center={center}
                        zoom={12}  // Nivel de zoom
                    >
                        {/* Marcadores dinámicos con coordenadas */}
                        {sucursalesConCoordenadas.map((sucursal, index) => (
                            <Marker
                                key={index}
                                position={{ lat: sucursal.lat, lng: sucursal.lng }}
                                title={sucursal.NOMBRE}
                                onClick={() => setSelectedSucursal(sucursal)}
                            />
                        ))}

                    </GoogleMap>
                ) : (
                    <Text style={tw`text-center text-primaryText`}>Cargando mapa...</Text>
                )}
                <View style={tw`mt-4 mb-10 ml-4 pr-4`}>
                    {sucursales.map((sucursal, index) => (
                        <View key={index}>
                            <View style={tw`flex-row`}>
                                <UbicacionIcon fill={'gray'} width={18} height={18} style={tw`mr-3 mt-2 min-w-[18px] min-h-[18px]`} />
                                <Text style={tw`text-datosText text-lg`}>{sucursal.CALLE} #{sucursal.EXTERIOR}, {sucursal.COLONIA}, {sucursal.MUNICIPIO}, {sucursal.ESTADO}</Text>
                            </View>
                            <View style={tw`h-0.1 bg-gray-400 mt-4 mb-4`}></View>
                        </View>
                    ))}
                </View>
            </View>
        </View>
    );
}
