import { ArrayValue } from 'aws-sdk/clients/rdsdataservice';
import { firmasAPI } from '../../api/firmasApi';
import { startUploadImagen, setImagenFrenteINE, setImagenReversoINE, setImagenFrenteLic, setImagenReversoLic, setImagenComprobanteDomicilio, setImagenPasaporte, setImagenFirma,  setImagenFirma2,  setImagenFirma3, setImagenFirma4, setImagenFirma5, setImagenFirma6, setImagenFirma7, setImagenFirma8, setImagenFirma9, setImagenFirma10, setImagenesFirmadas } from './imagenesSlice';

export const subeImagenFrenteINE = ( Success:boolean, location:string ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFrenteINE({imagenFrenteINE: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenReversoINE = ( Success:boolean, location:string ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenReversoINE({imagenReversoINE: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenFrenteLic = ( Success:boolean, location:string ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFrenteLic({imagenFrenteLic: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenReversoLic = ( Success:boolean, location:string ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenReversoLic({imagenReversoLic: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenComprobanteDomicilio = ( Success:boolean, location:string ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenComprobanteDomicilio({imagenComprobanteDomicilio: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenPasaporte = ( Success:boolean, location:string ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenPasaporte({imagenPasaporte: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenFirma = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma({imagenFirma: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
    }
}

export const subeImagenFirma2 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma2({imagenFirma2: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenFirma3 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma3({imagenFirma3: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
    
}

export const subeImagenFirma4 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma4({imagenFirma4: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
    
}

export const subeImagenFirma5 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma5({imagenFirma5: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
    
}

export const subeImagenFirma6 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma6({imagenFirma6: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
    }
}

export const subeImagenFirma7 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma7({imagenFirma7: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
}

export const subeImagenFirma8 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma8({imagenFirma8: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
    
}

export const subeImagenFirma9 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma9({imagenFirma9: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
    
}

export const subeImagenFirma10 = ( Success:boolean, location:string, id:any ) => {
    const formData = new FormData();
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('location', location);
        formData.append('id_sign', id);
        const responseData = await firmasAPI.post(`/contratos/send-images`, formData)
        .then(data => {
            dispatch( setImagenFirma10({imagenFirma10: Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
        
    }
    
}

export const subeImagenesFirmasData = ( firmas:any, numeroContrato: any, idEmpresa: any ) => {
    const formData = new FormData();
    const arrFirmas = firmas
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('firmas', arrFirmas);
        //formData.append('id_sign', id);
        const responseData = await firmasAPI.post(
            `/contratos/send-images`, 
            {
                "firmas": arrFirmas, 
                "numeroContrato": numeroContrato, 
                "idEmpresa": idEmpresa
            }
        )
        .then(data => {
            dispatch( setImagenesFirmadas({imagenesFirmadas: data.data.Success}) );
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
    }
}

export const subeImagenesDocumentosData = ( documentos:any, numeroContrato: any, idEmpresa: any  ) => {
    const formData = new FormData();
    const arrDocumentos = documentos
    return async(dispatch:any) =>{
        dispatch(startUploadImagen());
        formData.append('firmas', arrDocumentos);
        const responseData = await firmasAPI.post(
            `/contratos/send-images`, 
            {
                "documentos": arrDocumentos, 
                "numeroContrato": numeroContrato, 
                "idEmpresa": idEmpresa
            }
        )
        .then(data => {
            return data.data;
        })
        .catch(error => {
            console.log("Error : ",error);
        });
        return responseData;
    }
}