import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function LicenciaRevIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            id="Capa_2"
            data-name="Capa 2"
            viewBox="0 0 110.61 70.41"
            {...props}
        >
            <Defs>
            <LinearGradient
                id="Degradado_sin_nombre_165"
                x1={59.79}
                x2={51.56}
                y1={76.71}
                y2={0.55}
                data-name="Degradado sin nombre 165"
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0} stopColor="#aac5ff" />
                <Stop offset={1} stopColor="#f0f5ff" />
            </LinearGradient>
            </Defs>
            <G id="Capa_1-2" data-name="Capa 1">
            <Rect
                width={110.61}
                height={70.41}
                rx={8.8}
                ry={8.8}
                style={{
                fill: "url(#Degradado_sin_nombre_165)",
                }}
            />
            <Rect
                width={43.6}
                height={9.08}
                x={7.39}
                y={50.65}
                className="cls-2"
                rx={3.48}
                ry={3.48}
            />
            <Rect
                width={28.61}
                height={9.08}
                x={74.67}
                y={50.65}
                className="cls-2"
                rx={3.48}
                ry={3.48}
            />
            <Rect
                width={28.61}
                height={9.08}
                x={7.39}
                y={36.18}
                className="cls-2"
                rx={3.48}
                ry={3.48}
            />
            <Path d="M2.98 10.85h103.45v16.61H2.98z" />
            </G>
        </Svg>
    )
}