import React from 'react';
import { View, Pressable } from 'react-native'

import { useRoute } from '@react-navigation/native';

// Configuración twrnc
import tw from '../../styles/twrnc-config';
/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';
import Text from '../../components/Text';
import { useSelector, useDispatch } from "react-redux";


export default function HeaderFirmarDoc({navigation}){
    const route = useRoute();
    const documento = useSelector(state => state.selectedDoc.documento);
  const num = useSelector(state => state.selectedDoc.num);
  const id = useSelector(state => state.selectedDoc.id);
    // const { idEmpresa, codigoActivacion } = route.params;
    function goToFirmas(){
        navigation.navigate('ListFirmasScreen.Index');
    }

    return(
        <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
        <View style={tw.style(`flex-row`/* , validacion && "bg-red" */)}>
            <Pressable style={tw`mr-2 pt-9`} onPress={() => goToFirmas()}>
                <MaterialIcons name="arrow-back" size={32} color={tw.color("primary")} />
            </Pressable>
            <Text style={tw`text-primary text-3xl mt-8 font-500 `}>{documento}</Text>
        </View>
    </View>
    );
};