import React, { useState } from 'react';
import { View, Modal, Button, StyleSheet, Image, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import InfoIcon from '../../assets/icons/info';
import LicenciaFrente from '../../assets/icons/licenciaFrente';
import { Overlay } from '@rneui/themed';
import Text from '../../components/Text';
const ModalOtrosDoc = ({ visibleModalOtros, closeModalOtrosDoc, setSelectedDocParent }) => {
  let primaryColor = '#22c55e';
  const [selectedDoc, setSelectedDoc] = useState("");

  const selectedLicencia = () => {
    setSelectedDoc("Licencia");
  }

  const sleccionadoLic = () => {
    if (selectedDoc === "Licencia") {
      setSelectedDocParent("Licencia"); // Update parent state
    }
    closeModalOtrosDoc();
  }

  const cancelar = () => {
    setSelectedDoc("");
    setSelectedDocParent(""); // Update parent state
    closeModalOtrosDoc();
  }

  return (
    <Overlay
      isVisible={visibleModalOtros}
      windowBackgroundColor="rgba(0,0,0,0.5)"
      overlayBackgroundColor="transparent"
      overlayStyle={tw`bg-white w-9/10 border-0 justify-center items-center rounded-3xl mt-4 ml-5 mr-5`}
    >
      <View style={[tw`bg-white w-full pl-3 pr-3 pt-10 pb-10 items-center rounded-3xl`]}>
        <Text style={[tw`text-xl text-center text-primary font-500`]}>
          Otros Documentos
        </Text>
        <View style={[tw`pl-1 justify-center mb-10 mt-8`]}>
          {selectedDoc === "Licencia" ? (
            <View>
              <Pressable
                style={[tw`flex-row items-center pb-4 pt-4 pl-4 pr-4 border border-primary bg-tabs rounded-lg shadow-lg`]}
                onPress={selectedLicencia}
              >
                <LicenciaFrente style={tw`mr-4`} width={55} height={35} />
                <View style={tw`flex-1`}>
                  <Text style={tw`text-lg text-primaryText font-500`}>Licencia de conducir</Text>
                </View>
              </Pressable>
            </View>
          ) : (
            <View>
              <Pressable
                style={[tw`flex-row items-center pb-4 pt-4 pl-4 pr-4 rounded-lg shadow-lg`]}
                onPress={selectedLicencia}
              >
                <LicenciaFrente style={tw`mr-4`} width={55} height={35} />
                <View style={tw`flex-1`}>
                  <Text style={tw`text-lg text-primaryText`}>Licencia de conducir</Text>
                </View>
              </Pressable>
            </View>
          )}
        </View>
        <View style={tw`flex-row w-full mt-8`}>
          <Pressable
            style={[tw`flex-1 pt-3 pb-3 mr-1 bg-white border border-primary rounded-full`]}
            onPress={cancelar}
          >
            <Text style={[tw`text-center text-primary text-lg`]} numberOfLines={1}>
              Cancelar
            </Text>
          </Pressable>
          {selectedDoc !== "" ? (
            <Pressable
              style={[tw`flex-2 pt-3 pb-3 ml-1 bg-primary rounded-full`]}
              onPress={sleccionadoLic}
            >
              <Text style={[tw`text-center text-white text-lg`]} numberOfLines={1}>
                Aceptar
              </Text>
            </Pressable>
          ) : (
            <Pressable
              style={[tw`flex-2 pt-3 pb-3 ml-1 bg-buttonInac rounded-full`]}
            >
              <Text style={[tw`text-center text-textButton text-lg`]} numberOfLines={1}>
                Aceptar
              </Text>
            </Pressable>
          )}
        </View>
      </View>
    </Overlay>
  );
};

export default ModalOtrosDoc;
