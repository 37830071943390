import React from 'react';
import { Alert, View, StyleSheet, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import { useSelector } from "react-redux";
import { subeImagenFrenteINE } from '../../store/slices/imagenes/thunks';
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';

const FooterAltaDocumentos = ({ 
  navigation,
  uriFrenteINE,
  uriReversoINE,
  uriPasaporte,
  uriReversoLic,
  uriFrenteLic,
  subirINE,
  subirPasaporte,
  subirLicencia,
  isLoading,
}) => {
  // const route = useRoute();
  // const { idEmpresa, codigoActivacion } = route.params;
  const ImagenesData = useSelector( state => state.imagenes );

  const goToAltaDocumentosComDomi= async () => {
    if( (uriFrenteINE === false || uriReversoINE === false) && (uriPasaporte === false) && (uriFrenteLic === false || uriReversoLic === false)){
      alert("Selecciona las imagenes correspondientes");
      return;
    }
    let success;
    const urlDataExistInS3 = "telecable-images";
    if((!ImagenesData.imagenFrenteINE || !ImagenesData.imagenReversoINE)&&(uriFrenteINE && uriReversoINE)){
      success = true;
      /*if(!uriFrenteINE.includes(urlDataExistInS3)){
        success = await subirINE();
      }else{
        success = true;
      }*/
    }
    if((!ImagenesData.imagenPasaporte )&&(uriPasaporte)){
      success = true;
      /*
      if(!uriPasaporte.includes(urlDataExistInS3)){
        success = await subirPasaporte();
      }else{
        success = true;
      }*/
    }
    if((!ImagenesData.imagenFrenteLic || !ImagenesData.imagenReversoLic)&&(uriFrenteLic && uriReversoLic)){
      success = true;
      /*
      if(!uriFrenteLic.includes(urlDataExistInS3)){
        success = await subirLicencia();
      }else{
        success = true;
      }*/
    }

    if (success ||  (ImagenesData.imagenFrenteINE && ImagenesData.imagenReversoINE)  || (ImagenesData.imagenPasaporte ) || (ImagenesData.imagenFrenteLic && ImagenesData.imagenReversoLic)) {
      navigation.navigate('AltaDocumentosCompDomicilioScreen.Index');
    }
    
    
  }
  return (
    <View style={[tw`flex-row w-full pr-5 pl-5 pb-6 pt-6  justify-center items-center shadow-lg bg-white`]}>
      <Pressable 
        style={[tw`flex-1 py-3 mx-2 rounded-full`, isLoading ? tw`bg-encabezado` : tw`bg-primary`, (uriFrenteINE && uriReversoINE) || uriPasaporte || (uriFrenteLic && uriReversoLic) ? tw`` : tw`bg-buttonInac` ,styles.buttonFooter]} 
        onPress={goToAltaDocumentosComDomi}
        disabled={isLoading}
      >
        <Text style={[tw`text-center text-xl`,  isLoading ? tw`text-datosText` : tw`text-white`]}>
          {isLoading  ? 'Cargando...' : 'Continuar'}
        </Text>
      </Pressable>
       </View>
  );
};

const styles = StyleSheet.create({
  buttonFooter: {
    width: 150,
    height: 55,
},
});

export default FooterAltaDocumentos;
  
  