import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function ValidadoIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 194.35 194.35"
            {...props}
        >
            <G data-name="Capa 1">
            <Circle
                cx={97.17}
                cy={97.17}
                r={97.17}
                style={{
                fill: "#45ba47",
                }}
            />
            <Path
                d="M149.86 60.44c-4.58-4.58-12.02-4.58-16.6 0l-49.13 49.13-17.31-17.31c-4.58-4.58-12.02-4.58-16.6 0-4.58 4.58-4.58 12.02 0 16.6l25.04 25.04c2.44 2.44 5.68 3.57 8.87 3.41 3.19.15 6.43-.97 8.87-3.41l56.86-56.86c4.58-4.58 4.58-12.02 0-16.6Z"
                style={{
                fill: "#fff",
                }}
            />
            </G>
        </Svg>
    )   
}