import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function LicenciaIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            id="Capa_2"
            data-name="Capa 2"
            viewBox="0 0 110.61 70.41"
            {...props}
        >
            <Defs>
            <LinearGradient
                id="Degradado_sin_nombre_165"
                x1={59.79}
                x2={51.56}
                y1={76.71}
                y2={0.55}
                data-name="Degradado sin nombre 165"
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0} stopColor="#aac5ff" />
                <Stop offset={1} stopColor="#f0f5ff" />
            </LinearGradient>
            </Defs>
            <G id="Capa_1-2" data-name="Capa 1">
            <Rect
                width={110.61}
                height={70.41}
                rx={8.8}
                ry={8.8}
                style={{
                fill: "url(#Degradado_sin_nombre_165)",
                }}
            />
            <Circle
                cx={28.03}
                cy={24.5}
                r={10.76}
                style={{
                fill: "#e8bb8e",
                }}
            />
            <Path
                d="M35.12 40.26c-2.78-.88-11.14-1.17-14.18 0-3.04 1.17-9.65 3.09-9.65 6.9 0 3.81 3.09 6.9 6.9 6.9h19.7c3.81 0 6.9-3.09 6.9-6.9 0-3.81-6.87-6.02-9.65-6.9Z"
                style={{
                fill: "#5243ac",
                }}
            />
            <Rect
                width={43.6}
                height={9.08}
                x={58.01}
                y={24.82}
                className="cls-3"
                rx={3.48}
                ry={3.48}
            />
            <Rect
                width={28.61}
                height={9.08}
                x={72.99}
                y={42.23}
                className="cls-3"
                rx={3.48}
                ry={3.48}
            />
            <Path
                d="M15.99 21.75c3.96 3.21 20.65-7.17 22.8 4.14 0 0 1.94-12.39-9.05-13.8-10.99-1.41-13.75 9.66-13.75 9.66Z"
                style={{
                fill: "#603813",
                }}
            />
            </G>
        </Svg>
    )
}