import { StatusBar, } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, View, Pressable, TextInput } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from '../../hooks/useCachedResources';
import useColorScheme from '../../hooks/useColorScheme';
import Navigation from '../../navigation';
import Toast, { ToastProvider } from 'react-native-toast-notifications'
//  react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()
// Configuración twrnc
import tw from "../../styles/tailwind";
import { useDeviceContext } from 'twrnc'
//-------------------------Configuración redux-------------------------------------------//
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../../store/configureStore";
const { store, persistor } = configureStore();
//-------------------------Configuración de API-------------------------------------------//
import api from "../../utils/api";
import Constants from 'expo-constants'
/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';
import RechazadoIcon from '../../assets/icons/rechazado';
import ValidadoIcon from '../../assets/icons/validado';
import Text from '../../components/Text';

import { useRoute } from '@react-navigation/native';

export default function HeaderValidar({navigation}) {
    
    /* Estado que valida si el pago fue correcto o no */
    let pagoValidado = false;
    return(
        <View style={[tw`w-full bg-encabezado border-b border-primary shadow-lg justify-center items-center`, { height: 350 }]}>
            {pagoValidado ? (
                <View style={[tw`items-center`]} >
                    <ValidadoIcon  width={100} height={100}/>
                    <Text style={tw`text-primary text-5xl mt-4 font-500`}>¡Pago Exitoso!</Text>
                </View>
            ) : (
                <View style={[tw`items-center`]}>
                    <RechazadoIcon  width={100} height={100}/>
                    <Text style={tw`text-primary text-5xl mt-4 font-500`}>Pago Declinado</Text>
                </View>
            )
            }
        </View>
           
    );
};


