import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { WorkSans_600SemiBold } from '@expo-google-fonts/work-sans';
import * as Font from 'expo-font';
import { useRoute } from '@react-navigation/native';

/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';

const styles = StyleSheet.create({
    textOverlay: {
        top: '27%',
        left: '90%',
        transform: [{ translateX: -50 }, { translateY: -50 }],
        borderColor: "#000",
    },
    touchableOverlay: {
        top: 15,
        left: 10,
        borderColor: "#000",
        borderBottomRightRadius: 20,
    },
    textInforme: {
        top: 15,
        left: 15,
        width: "100%",
        fontSize: 16,
    },
    textQuestion: {
        top: 40,
        left: 20,
        fontSize: 16,
        textDecorationLine: "underline"
    }
});

export default function HeaderAltaDocumentosCompDomi({navigation}) {
    // const route = useRoute();
    // const { idEmpresa, codigoActivacion } = route.params;
    const goToAtaDocumentoINE= () => {
        navigation.navigate('AltaDocumentosScreen.Index');
    }

    /**
     * Ejemplo de petición a una API
     */
    
  
    return (
        <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
            <View style={tw`flex-row items-center`}>
                <Pressable style={tw`mr-2`} onPress={goToAtaDocumentoINE}>
                    <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
                </Pressable>
                <Text style={tw`text-primary text-3xl mt-10 font-500`}>Comprobante de domicilio</Text>
            </View>
        </View>
    );
  };

  
  