import * as React from 'react';
import { SafeAreaView } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';

import HeaderAltaDocumentos from './HeaderAltaDocumentos';
import FormaAltaDocumentos from './FormaAltaDocumentos';
import FooterAltaDocumentos from './FooterAltaDocumentos';

export default function AltaDocumentosScreen({navigation}) {
  return (
    <SafeAreaView style={tw`bg-bgGrey flex-1`}>
        <HeaderAltaDocumentos navigation={navigation} />
        <FormaAltaDocumentos navigation={navigation} />
    </SafeAreaView>
  );
}