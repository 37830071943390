import { createSlice } from "@reduxjs/toolkit";

export const documentosSlice = createSlice({
    name: 'documentos',
    initialState: {
      documentos: [],
      documentosUrls: [],
      isLoading: false
    },
    reducers: {
        startUploadDocumento: (state) => {
            state.isLoading = true;
        },
        setDocumentos: (state, action) => {
            state.isLoading = false
            state.documentos = action.payload.documentos;
        },
        endUploadDocumento: (state) => {
            state.isLoading = false
        },
        setDocumentoURL: ( state, action ) => {
            let index = state.documentosUrls.findIndex(item => item.tipo_documento === action.payload.newDocumento.tipo_documento);

            if (index !== -1) {
                if (index !== -1) {
                    state.documentosUrls.splice(index, 1); 
                  }
            }

            state.documentosUrls = [...state.documentosUrls, action.payload.newDocumento];
            
            
            /*if(!state.documentosUrls.includes(action.payload.newDocumento)){
                state.documentosUrls = [...state.documentosUrls, action.payload.newDocumento] ;
            }*/

            state.isLoading = false;
        },
        deleteReversoPasaporte: (state) => {
            let index = state.documentosUrls.findIndex(item => item.tipo_documento === "identificacion_reverso");

            if (index !== -1) {
                if (index !== -1) {
                    state.documentosUrls.splice(index, 1); 
                  }
            }

            state.isLoading = false;
        },
        setCleanDocumentoUrls: (state) => {
            state.documentosUrls = [] ;
        }
    },
  });
  
  export const { startUploadDocumento, setDocumentos, endUploadDocumento, setDocumentoURL, setCleanDocumentoUrls, deleteReversoPasaporte } = documentosSlice.actions;

  export default documentosSlice.reducer;
  