import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet, Platform } from 'react-native';
import PDFReader from 'rn-pdf-reader-js';
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import FullScreenIcon from '../../assets/icons/fullScreen';
import { useRoute, useNavigation } from '@react-navigation/native';

export default function DocumentoPDF({ documento, id, docFirmar }) {
  // const route = useRoute();
  const navigation = useNavigation();
  // const { idEmpresa, codigoActivacion } = route.params;

  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    const numericId = parseInt(id);

    // Recorre el objeto docFirmar y busca coincidencia con el id
    const matchingDocument = docFirmar.find(doc => {
      return doc.idDocumento === numericId;
    });

    if (matchingDocument) {
      //console.log('Documento encontrado:', matchingDocument); // Para verificar el documento encontrado
      setPdfUrl(matchingDocument.url);
    } else {
      console.log('No se encontró ningún documento con ese id'); // Para verificar cuando no se encuentra el documento
    }
  }, [docFirmar, id]);

  const openFulPDF = () => {
    navigation.navigate('FullPDF');
  };

  return (
    <View style={[tw``]}>
      <View style={[tw`w-full relative`]}>
        <View style={tw`z-10`}>
          {Platform.OS !== 'web' ? (
            <PDFReader
              source={{ uri: pdfUrl }}
              style={styles.pdf}
            />
          ) : (
            <iframe
              src={pdfUrl}
              style={styles.pdf}
              title="PDF"
            />
          )}
        </View>
        <TouchableOpacity
          onPress={openFulPDF}
          style={[tw`absolute z-20 bottom-2 inset-x-0 bottom-0 bg-primary rounded-xl p-2 ml-8 mr-8 mb-5`]}
        >
          <View style={tw`flex-row justify-between`}>
            <Text style={tw`text-white text-lg text-center`}>Ver en pantalla completa</Text>
            <FullScreenIcon fill={'white'} width={26} height={26} style={[tw`mr-3`]} />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  pdf: {
    width: '100%',
    height: 450,
  },
});
