/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';


// Configuración de las URL
// https://reactnavigation.org/docs/deep-linking/
// Nombres de ruta de react Navigation https://reactnavigation.org/docs/configuring-links


const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          TabOne: {
            screens: {
              TabOneScreen: 'one',
            },
          },
          TabTwo: {
            screens: {
              TabTwoScreen: 'two',
            },
          },
        },
      },
      Modal: 'modal',
      NotFound: '*',

      "InicioScreen.Index": 'contrato/:t',
      "DatosPersonalesScreen.Index": 'contrato/informacion',
      "ListFirmasScreen.Index": 'contrato/documentos',
      "AltaDocumentosScreen.Index": 'contrato/alta-INE',
      "AltaDocumentosCompDomicilioScreen.Index": 'contrato/alta-domicilio',
      "MetodoPagoScreen.Index": 'contrato/metodo-pago',
      "MetodoPagoEfectivoScreen.Index": 'contrato/pago-efectivo',
      // "PagoTarjetaScreen.Index": 'contrato/pago-tarjeta',
      "PagoExitoso.PagoExitoso": 'contrato/pago-exitoso',
      "PagoError.PagoError": 'contrato/pago-error',
      "CompletaPagoEfectivoScreen.Index": 'contrato/:mensaje/datos-efectivo', 
      "ValidarPagoScreen.Index": 'contrato/validar-pago',
      "FirmarDocumentosScreen.Index": 'contrato/firmar-documento/',
      "FullPDF": 'contrato/firmar-documento/FullPDF',
      "PagoOxxoScreen.Index": 'contrato/pago-oxxo',
      "PagoSucursalScreen.Index": 'contrato/pago-sucursal',
      "Examples.Detail": 'ejemplos/:sellId',

      "Auth.Login": "auth/login",
    },
  },
};

export default linking;
