import { contratosAPI } from '../../api/contratosApi';
import { startFirmaDocumento, setFirmaDocumento, errorFirmaDocumento, endRecoverFirmas } from './firmasSlice';
import Constants from 'expo-constants'

export const getLinkToSign = ( urlDocumento:string, tipoDocumento:string,  numeroContrato:string, firmasDocumentos:any, idEmpresa:string, codigoActivacion:string, currentURL:any) => {
    return async( dispatch:any ) => {
        dispatch( startFirmaDocumento() );
        const existe = firmasDocumentos.find(item => item.numero_contrato === numeroContrato && item.tipo_documento === tipoDocumento);
        if (!existe) {
            const responseData = await contratosAPI.post(`/contratos/link-documentos`, 
                {
                    urlDocumento: urlDocumento, 
                    tipoDocumento: tipoDocumento, 
                    numeroContrato: numeroContrato,
                    idEmpresa: idEmpresa,
                    codigoActivacion: codigoActivacion,
                    currentURL: currentURL
                }
            )
            .then(data => {
                const recoverSignLink = data.data.signing_link;
                console.log("********* RECOVERSIGNLINK DATA : ",recoverSignLink);
                const newElemento = {
                                        document_id:recoverSignLink.document_id, 
                                        numero_contrato: recoverSignLink.numero_contrato, 
                                        tipo_documento: recoverSignLink.tipo_documento,
                                        sign_link_url: recoverSignLink.url,
                                        firmado:"false"
                                    }
                
                firmasDocumentos = [...firmasDocumentos, newElemento];

                dispatch( setFirmaDocumento({firmas:firmasDocumentos}) );

                dispatch( endRecoverFirmas() );
                return {"Success": true, "url": recoverSignLink.url, "document_id": recoverSignLink.document_id};
            })
            .catch(error => {
                console.log("**** ERROR : ",error);
                const errorMessageData = "No se pudo generar el link para ese documento";
                dispatch( errorFirmaDocumento({errorMessage: errorMessageData}) );
                dispatch( endRecoverFirmas() );
                return {"Success": false, "Error":errorMessageData};
            });
        
            //dispatch( endRecoverContratos() );
            return responseData;
        } else {
            dispatch( endRecoverFirmas() );
            return {"Success": false, "url": existe.sign_link_url, "message":"ya_existe", "document_id": existe.document_id};
        }
    }
}

export const verificaSignDocument = ( document_id:string, numero_contrato:string, tipo_documento:string) => {
    tipo_documento = tipo_documento.trim().replace(/\s/g, '_');

    return async( dispatch:any ) => {
        const responseData = await contratosAPI.get(`/get-document-signed?document_id=${document_id}&numero_contrato=${numero_contrato}&prefijo_nombre_file=${tipo_documento}`)
            .then(data => {
                const recoverSignDocument = data.data;
                //console.log("DATADATADATA : ",recoverSignDocument);
                let firmado = false;
                let urlDocumentoFirmado = "";
                if(recoverSignDocument.Success === "true"){
                    urlDocumentoFirmado=recoverSignDocument.url_document;
                    firmado = true;
                }
                const responseDataFirmado = {"firmado": firmado, "urlDocumentoFirmado": urlDocumentoFirmado};
                //console.log("**** responseDataFirmado : ",responseDataFirmado);
                return responseDataFirmado;
            })
            .catch(error => {
                //console.log("**** ERROR : ",error);
                const errorMessageData = "El documento no ha sido firmado";
                const responseDataFirmado = {"firmado": false, "urlDocumentoFirmado": ""};
                return responseDataFirmado;
            });
        
            //console.log("**** RESPONSEDATA : ",responseData);
            return responseData;
    }
}