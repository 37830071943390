import { contratosAPI } from '../../api/contratosApi';
import { startGetContrato, setContrato, errorContrato, endRecoverContratos } from './contratosSlice';
import Constants from 'expo-constants'


export const getContratoData = ( t:any) => {
    return async( dispatch:any ) => {
        dispatch( startGetContrato() );
        //const responseData = await contratosAPI.get(`/contratos/recupera_datos?id_activacion=${id_activacion}&id_empresa=${id_empresa}`)
        const responseData = await contratosAPI.get(`/contratos/recupera_datos?t=${t}`)
        .then(data => {
            dispatch( setContrato({contratos:data.data.dataContrato}) );
            return data.data;
        })
        .catch(error => {
            const errorMessageData = "No existe información para ese Id de Activación"
            dispatch( errorContrato({errorMessage: errorMessageData}) );
            return errorMessageData;
        });
       
        dispatch( endRecoverContratos() );
        return responseData;
    }
}