import { createSlice } from '@reduxjs/toolkit';

export const selectedDocSlice = createSlice({
  name: 'selectedDoc',
  initialState: {
    documento: '',
    num: '',
    id: '',
    numero_contrato: '',
    id_empresa: '',
    idActivacion: '',
  },
  reducers: {
    setSelectedDoc: (state, action) => {
      // Desestructuramos el payload
      const { documentoFimar, noDoc, idDocumento } = action.payload;
      state.documento = documentoFimar;
      state.num = noDoc;
      state.id = idDocumento;
    },
    clearSelectedDoc: (state) => {
      state.documento = '';
      state.num = '';
      state.id = '';
    },
    setNumeroContratoIdEmpresa: (state, action) => {
      state.numero_contrato = action.payload.numeroContrato;
      state.id_empresa = action.payload.idEmpresa;
      state.idActivacion = action.payload.idActivacion;
    }
  }
});

export const { setSelectedDoc, clearSelectedDoc, setNumeroContratoIdEmpresa } = selectedDocSlice.actions;
export default selectedDocSlice.reducer;
