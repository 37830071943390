import * as React from "react"
import Svg, {
  Defs,
  LinearGradient,
  Stop,
  G,
  Rect,
  Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function DocumentoIcon(props) {
    return(
      <Svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 2"
      viewBox="0 0 89.46 107.47"
      {...props}
    >
      <Defs>
        <LinearGradient
          id="a"
          x1={6.14}
          x2={65.89}
          y1={112.67}
          y2={21.42}
          data-name="Degradado sin nombre 173"
          gradientUnits="userSpaceOnUse"
        >
          <Stop offset={0} stopColor="#ded1c3" />
          <Stop offset={1} stopColor="#f8f5f2" />
        </LinearGradient>
      </Defs>
      <G data-name="Capa 1">
        <Rect
          width={89.46}
          height={107.47}
          rx={8.8}
          ry={8.8}
          style={{
            fill: "url(#a)",
          }}
        />
        <Path
          d="M77.86 40.16v.39c0 1.92-1.56 3.48-3.48 3.48H15.75c-1.92 0-3.48-1.56-3.48-3.48v-.39c0-1.92 1.56-3.48 3.48-3.48h58.63c1.92 0 3.48 1.56 3.48 3.48ZM49.38 48.7H15.75c-1.92 0-3.48 1.56-3.48 3.48v.39c0 1.92 1.56 3.48 3.48 3.48h33.62c1.92 0 3.48-1.56 3.48-3.48v-.39c0-1.92-1.56-3.48-3.48-3.48Zm13.95 12.02H15.75c-1.92 0-3.48 1.56-3.48 3.48v.39c0 1.92 1.56 3.48 3.48 3.48h47.57c1.92 0 3.48-1.56 3.48-3.48v-.39c0-1.92-1.56-3.48-3.48-3.48ZM53.17 10.56H36.98c-1.92 0-3.48 1.56-3.48 3.48v.39c0 1.92 1.56 3.48 3.48 3.48h16.19c1.92 0 3.48-1.56 3.48-3.48v-.39c0-1.92-1.56-3.48-3.48-3.48Zm30.28 17.2H4.62v1.16h78.82v-1.16Zm0 48.07H4.62v1.16h78.82v-1.16ZM11.73 87.75h-1.34v11.41h1.34V87.75Zm11.03 0h-1.34v11.41h1.34V87.75Zm8.42 0h-1.34v11.41h1.34V87.75Zm2.76 0H32.6v11.41h1.34V87.75Zm-17.29 0h-1.9v11.41h1.9V87.75Zm9.69 0h-1.9v11.41h1.9V87.75Zm13.81 0h-3.04v11.41h3.04V87.75Zm-26.56 0h-.67v11.41h.67V87.75Zm6.75 0h-.67v11.41h.67V87.75Zm15.7 0h-.67v11.41h.67V87.75Zm-7.61 0h-.67v11.41h.67V87.75Zm-9.93 0h-.67v11.41h.67V87.75Z"
          style={{
            fill: "#999",
          }}
        />
      </G>
    </Svg>
    )
}