import React, { useEffect, useState } from 'react';
import { TouchableOpacity, View, StyleSheet, Platform, Dimensions, Modal } from 'react-native';
import PDFReader from 'rn-pdf-reader-js'; // Alternativa compatible con la web
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import FullScreenIcon from '../../assets/icons/fullScreen';

import { useRoute, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';

export default function FullPDF() {
  const route = useRoute();
  const navigation = useNavigation();
  const Contrato = useSelector(state => state.contratos);
  const docFirmar = Contrato.contratos.documentos;
  const documento = useSelector(state => state.selectedDoc.documento);
  const num = useSelector(state => state.selectedDoc.num);
  const id = useSelector(state => state.selectedDoc.id);
  const [pdfUrl, setPdfUrl] = useState('');
  const [modalVisible, setModalVisible] = useState(true); // Controla la visibilidad del modal

  useEffect(() => {
    const numericId = parseInt(id);

    // Recorre el objeto docFirmar y busca coincidencia con el id
    const matchingDocument = docFirmar.find(doc => {
      return doc.idDocumento === numericId;
    });

    if (matchingDocument) {
      //console.log('Documento encontrado:', matchingDocument); // Para verificar el documento encontrado
      setPdfUrl(matchingDocument.url);
    } else {
      console.log('No se encontró ningún documento con ese id'); // Para verificar cuando no se encuentra el documento
    }
  }, [docFirmar, id]);

  const closeModal = () => {
    setModalVisible(false); // Cierra el modal
    navigation.goBack(); // Regresa a la pantalla anterior si es necesario
  };

  return (
    <Modal
      visible={modalVisible}
      animationType="slide"
      onRequestClose={closeModal}
      transparent={true}
    >
      <View style={styles.modalContainer}>
        <TouchableOpacity
          onPress={closeModal}
          style={[tw`absolute z-20 bottom-2 inset-x-0 bottom-0 bg-primary rounded-xl p-2 ml-16 mr-16 mb-5`]}
        >
          <Text style={tw`text-white text-lg text-center`}>Cerrar</Text>
        </TouchableOpacity>
        <View style={styles.pdfContainer}>
          {Platform.OS !== 'web' ? (
            <PDFReader
              source={{ uri: pdfUrl }}
              style={styles.pdf}
            />
          ) : (
            <iframe
              src={pdfUrl}
              style={styles.pdf}
              title="PDF"
            />
          )}
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fondo semitransparente para el modal
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdfContainer: {
    width: '90%', // Ancho del PDF casi en pantalla completa
    height: '90%', // Altura del PDF casi en pantalla completa
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdf: {
    width: '100%',
    height: '100%',
  },
});
