import React, {useState, useEffect} from 'react';
import { View, TextInput } from 'react-native'


// Configuración twrnc
import tw from '../../styles/twrnc-config';
import { Montserrat_400Regular } from '@expo-google-fonts/montserrat';

/* Iconos */
import UsuarioIcon from '../../assets/icons/nombre'
import CellIcon from '../../assets/icons/celular'
import CorreoIcon from '../../assets/icons/correo'
import CalendarioIcon from '../../assets/icons/calendario'
import UbicacionIcon from '../../assets/icons/ubicacion'
import PaqueteIcon from '../../assets/icons/paquete'
import SucursalIcon from '../../assets/icons/sucursal'
import TvIcon from '../../assets/icons/tv'
import ContratoIcon from '../../assets/icons/contrato'

import InfoIcon from '../../assets/icons/info';
import Text from '../../components/Text';

export default function Datos({navigation, Contrato}){
    
    const [nombre, setNombre] = useState(""); 
    const [apellidos, setApellidos] = useState(""); 
    const [numeroCelular, setNumeroCelular] = useState(""); 
    const [numeroTelefono, setNumeroTelefono] = useState(""); 
    const [correoElectronico, setCorreoElectronico] = useState(""); 
    const [fechaNacimiento, setFechaNacimiento] = useState(""); 
    const [codigoPostal, setCodigoPostal] = useState(""); 
    const [estado, setEstado] = useState(""); 
    const [municipio, setMunicipio] = useState(""); 
    const [colonia, setColonia] = useState(""); 
    const [calle, setCalle] = useState(""); 
    const [numeroExterior, setNumeroExterior] = useState(""); 
    const [referencias, setReferencias] = useState(""); 
    const [paquete, setPaquete] = useState(""); 
    const [numeroTvs, setNumeroTvs] = useState(""); 
    const [sucursalContratacion, setSucursalContratacion] = useState(""); 
    const [numeroContrato, setNumeroContrato] = useState(""); 

    useEffect(() => {
        setNombre(Contrato.nombre);
        setApellidos(Contrato.apellidos);
        setNumeroCelular(Contrato.numeroCelular);
        setNumeroTelefono(Contrato.numeroTelefono);
        setCorreoElectronico(Contrato.correoElectronico);
        setFechaNacimiento(Contrato.fechaNacimiento);
        setCodigoPostal(Contrato.codigoPostal);
        setEstado(Contrato.estado);
        setMunicipio(Contrato.municipio);
        setColonia(Contrato.colonia);
        setCalle(Contrato.calle);
        setNumeroExterior(Contrato.numeroExterior);
        setReferencias(Contrato.referencias);
        setPaquete(Contrato.paquete);
        setNumeroTvs(Contrato.numeroTvs);
        setSucursalContratacion(Contrato.sucursalContratacion);
        setNumeroContrato(Contrato.numeroContrato);
      }, []);

    //console.log("*****Contrato : ",Contrato);
    let primaryColor = '#45BA47';
    return(
            <View  style={tw``}>
                {/* Sección de Datos Personales */}
                <View>
                <Text style={tw`text-2xl text-primary ml-6 mt-6 mb-2`}>Datos Personales</Text>
                    <View style={tw`flex-row`}>
                        <UsuarioIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Nombre</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{nombre}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-14`}>Apellidos</Text>
                    </View> 
                    <Text style={tw`text-xl text-datosText py-2 pl-14`}>{apellidos}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                    <View style={tw`flex-row`}>
                        <CellIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Celular</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{numeroCelular}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-14`}>Teléfono</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-14`}>{numeroTelefono}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                    <View style={tw`flex-row`}>
                        <CorreoIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={[tw`text-xl text-primaryText mt-4 mb-2 ml-2`]}>Correo electrónico</Text>
                    </View>
                    <Text style={[tw`text-xl text-datosText py-2 pl-13 ml-1`]}>
                        {correoElectronico ? correoElectronico : <Text style={tw`text-red`}>No se registró correo electrónico</Text>}
                    </Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                    <View style={tw`flex-row`}>
                        <CalendarioIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Fecha de nacimiento</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{fechaNacimiento}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                </View>
                
                {/* Sección de Domicilio */}
                <View>
                <Text style={tw`text-2xl text-primary ml-6 mt-6 mb-2`}>Domicilio actual</Text>
                        
                        <View style={tw`flex-row`}>
                            <UbicacionIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                            <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Código postal</Text>
                        </View>
                        <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{codigoPostal}</Text>
                        <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                        <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>Estado</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13`}>{estado}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>Municipio</Text>
                    </View>
                    <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>{municipio}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>Colonia</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13`}>{colonia}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>Calle</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13`}>{calle}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>No. Ext.</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13`}>{numeroExterior}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-14`}></View>
                    <View style={tw`flex-row`}>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 pl-13`}>Referencias</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText border-gray-300 py-2 pl-13`}>{referencias}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                </View>

                {/* Sección de Servicio */}
                <View>
                <Text style={tw`text-2xl text-primary ml-6 mt-6 mb-2`}>Servicio</Text>
                        
                    <View style={tw`flex-row`}>
                            <PaqueteIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                            <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Paquete</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{paquete}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                    <View style={tw`flex-row`}>
                        <TvIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Número de TVs</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{numeroTvs}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                    <View style={tw`flex-row`}>
                        <SucursalIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>Sucursal</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{sucursalContratacion}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                    <View style={tw`flex-row`}>
                        <ContratoIcon fill={'gray'} width={22} height={22} style={tw`pl-4 pt-4 pb-4 ml-2`}/>
                        <Text style={tw`text-xl text-primaryText mt-4 mb-2 ml-2`}>No. de contrato</Text>
                    </View>
                    <Text style={tw`text-xl text-datosText py-2 pl-13 ml-1`}>{numeroContrato}</Text>
                    <View style={tw` h-0.1 bg-gray-400 mt-1 mr-6 ml-6`}></View>
                </View>

                {/* Advertencia */}
                <View>
                    <View style={tw`bg-tabs mt-8 rounded-lg p-3 items-center mb-4 ml-6 mr-6`}>
                        <InfoIcon heigh={32} width={32} fill={primaryColor} style={tw`mb-4 mt-2`} />
                        <Text style={tw`text-xl text-center text-datosText mb-6`}>
                            Al continuar otorgas tu consentimiento para que consultemos los datos de tu identificación ante el INE.
                        </Text>
                    </View>
                    {!correoElectronico ? 
                        <View style={tw`bg-red-100 mt-8 rounded-lg p-3 items-center mb-4 ml-6 mr-6`}>
                            <Text style={tw`text-xl text-center text-datosText mb-4 mt-4`}>
                             No se registró correo electrónico
                            </Text>
                         </View>
                    :
                    <></>
                    }
                    
                    <View style={tw`p-3 items-center ml-4 mr-4`}>
                        <Text style={tw`m-4 text-xl text-center text-datosText`}>
                                Si algún dato es incorrecto presiona el botón "Ayuda" para cambiarlo.
                        </Text>
                    </View>
                    <View style={tw` h-0.1 bg-gray-400 mt-4 mr-6 ml-6`}></View>
                </View>
            </View>

    );
}