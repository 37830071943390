import * as React from 'react';
import { Button, StyleSheet, Text, TextInput, View } from 'react-native'

import tw from '../../styles/tailwind';


export default function SellsDetailScreen({ route, navigation }) {
  /* 2. Aqui se obtiene el parámetro */
  const { venta } = route.params;

  return (
    <View style={tw`flex-1 items-center justify-center`}>
      <Text>Venta #{venta.folio}</Text>
      <Text>Cliente {venta.nombre_cliente}</Text>
    </View>
  );
}
