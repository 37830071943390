import React, { useEffect, useState } from 'react';
import { SafeAreaView, View, Pressable, TextInput, StyleSheet } from 'react-native'
import { useRoute } from '@react-navigation/native';
// Configuración twrnc
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { Montserrat_400Regular } from '@expo-google-fonts/montserrat';

export default function FooterInicio({navigation, codigoActivacion, idEmpresa}) {
    const route = useRoute();
    const { t } = route.params;
    let estadoContrato = "noPagado";
    function goToDatos(){
        navigation.navigate('DatosPersonalesScreen.Index');
    }
    return(
        <SafeAreaView style={tw`w-full flex-1 items-center bg-neutral-200`}>
        {estadoContrato == "noPagado" ? (
                <View style={tw`w-full justify-center bg-white p-6 shadow-lg`}>
                    <>
                    {codigoActivacion !== "" && codigoActivacion !== undefined ?
                    (
                    <Pressable style={tw`bg-primary py-4 rounded-full`} onPress={() => goToDatos()}>
                        <Text style={tw`text-center text-xl text-white text-lg`}>Comenzar</Text>
                    </Pressable>
                    )
                    :
                    (
                    <Pressable style={[tw`flex-1 py-4  bg-buttonInac rounded-full`, styles.buttonFooter]} >
                        <Text style={[tw`text-center text-xl text-textButton text-lg`]}>Comenzar</Text>
                    </Pressable>
                    )
                    }
                 </>
                </View>
            ) : (
                <View></View>
            )
        }
        {estadoContrato == "pagoEfectivo" ? (
            <View>
                    <Text style={tw`text-gray-500 text-center text-xl mb-2 `}>Datos de cobro</Text>
                    <TextInput
                        style={[tw`bg-white text-base text-center rounded-lg p-4`, {width: 300, fontFamily:Montserrat_400Regular}]}
                        readOnly={true}
                        multiline 
                        rows={3}
                        value="Visita programada en un horario de 9:00 a 12:00 para el dia de hoy"
                    />
            </View>
            ): (
                <View></View>
            )
        }
    </SafeAreaView>
    );

};

const styles = StyleSheet.create({
    buttonFooter: {
      width: "100%",
      height: 55,
  },
  });


