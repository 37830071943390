import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function UsuarioIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            id="Capa_2"
            data-name="Capa 2"
            viewBox="0 0 25.64 30.86"
            {...props}
        >
            <Defs></Defs>
            <G id="Capa_1-2" data-name="Capa 1">
            <Circle cx={12.82} cy={8.24} r={8.24} className="cls-1" />
            <Path
                d="M18.25 20.3c-2.13-.67-8.53-.9-10.86 0S0 22.66 0 25.58c0 2.92 2.36 5.28 5.28 5.28h15.08c2.92 0 5.28-2.36 5.28-5.28 0-2.92-5.26-4.61-7.39-5.28Z"
                className="cls-1"
            />
            </G>
        </Svg>
    )   
}