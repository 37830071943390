import React from 'react';
import { View, Modal, ActivityIndicator } from 'react-native';
import { Overlay } from '@rneui/themed';
import tw from '../../styles/tailwind';
export const ModalCarga = ({ visibleCarga, closeModalCarga }) => {
  let primaryColor = '#22c55e';
  return (
    <Overlay
        isVisible={visibleCarga}
        windowBackgroundColor="rgba(0,0,0,0.5)"
        overlayBackgroundColor="transparent"
        overlayStyle={tw`bg-transparent border-0 justify-center items-center mt-22 pt-5 pb-5 pl-10 pr-10`}
    >
        
        <ActivityIndicator size="large" color="white" />
        
    </Overlay>
  );
};
