import { createSlice } from "@reduxjs/toolkit";

export const imagenesSlice = createSlice({
    name: 'imagenes',
    initialState: {
      imagenFrenteINE: false,
      imagenReversoINE: false,
      imagenComprobanteDomicilio: false,
      imagenPasaporte: false,
      imagenFrenteLic: false,
      imagenReversoLic: false,
      imagenFirma: false,
      imagenFirma2: false,
      imagenFirma3: false,
      imagenFirma4: false,
      imagenFirma5: false,
      imagenFirma6: false,
      imagenFirma7: false,
      imagenFirma8: false,
      imagenFirma9: false,
      imagenFirma10: false,
      imagenesFirmadas: false,
      isLoading: false
    },
    reducers: {
        setEmptyUploadImagen: (state) => {
            state.imagenFrenteINE = false,
            state.imagenReversoINE = false,
            state.imagenComprobanteDomicilio = false,
            state.imagenPasaporte = false,
            state.imagenFrenteLic = false,
            state.imagenReversoLic = false,
            state.imagenFirma = false,
            state.imagenFirma2 = false,
            state.imagenFirma3 = false,
            state.imagenFirma4 = false,
            state.imagenFirma5 = false,
            state.imagenFirma6 = false,
            state.imagenFirma7 = false,
            state.imagenFirma8 = false,
            state.imagenFirma9 = false,
            state.imagenFirma10 = false,
            state.imagenesFirmadas = false,
            state.isLoading = false
        },
        startUploadImagen: (state) => {
            state.isLoading = true;
        },
        setImagenFrenteINE: (state, action) => {
            state.isLoading = false;
            state.imagenFrenteINE = action.payload.imagenFrenteINE;
        },
        setImagenReversoINE: (state, action) => {
            state.isLoading = false
            state.imagenReversoINE = action.payload.imagenReversoINE;
        },
        setImagenComprobanteDomicilio: (state, action) => {
            state.isLoading = false
            state.imagenComprobanteDomicilio = action.payload.imagenComprobanteDomicilio;
        },
        setImagenPasaporte: (state, action) => {
            state.isLoading = false
            state.imagenPasaporte = action.payload.imagenPasaporte;
        },
        setImagenFrenteLic: (state, action) => {
            state.isLoading = false;
            state.imagenFrenteLic = action.payload.imagenFrenteLic;
        },
        setImagenReversoLic: (state, action) => {
            state.isLoading = false
            state.imagenReversoLic = action.payload.imagenReversoLic;
        },

        setImagenFirma: (state, action) => {
            state.isLoading = false
            state.imagenFirma = action.payload.imagenFirma;
        },

        setImagenFirma2: (state, action) => {
            state.isLoading = false
            state.imagenFirma2 = action.payload.imagenFirma2;
        },

        setImagenFirma3: (state, action) => {
            state.isLoading = false
            state.imagenFirma3 = action.payload.imagenFirma3;
        },
        setImagenFirma4: (state, action) => {
            state.isLoading = false
            state.imagenFirma4 = action.payload.imagenFirma4;
        },
        setImagenFirma5: (state, action) => {
            state.isLoading = false
            state.imagenFirma5 = action.payload.imagenFirma5;
        },
        setImagenFirma6: (state, action) => {
            state.isLoading = false
            state.imagenFirma6 = action.payload.imagenFirma6;
        },

        setImagenFirma7: (state, action) => {
            state.isLoading = false
            state.imagenFirma7 = action.payload.imagenFirma7;
        },

        setImagenFirma8: (state, action) => {
            state.isLoading = false
            state.imagenFirma8 = action.payload.imagenFirma8;
        },
        setImagenFirma9: (state, action) => {
            state.isLoading = false
            state.imagenFirma9 = action.payload.imagenFirma9;
        },
        setImagenFirma10: (state, action) => {
            state.isLoading = false
            state.imagenFirma10 = action.payload.imagenFirma10;
        },
        setImagenesFirmadas: (state, action) => {
            state.isLoading = false;
            state.imagenesFirmadas = action.payload.imagenesFirmadas;
        }
    },
  });
  
  export const { setEmptyUploadImagen, startUploadImagen, setImagenFrenteINE, setImagenReversoINE,  setImagenFrenteLic, setImagenReversoLic, setImagenComprobanteDomicilio, setImagenPasaporte, setImagenFirma, setImagenFirma2, setImagenFirma3, setImagenFirma4, setImagenFirma5, setImagenFirma6, setImagenFirma7, setImagenFirma8, setImagenFirma9, setImagenFirma10, setImagenesFirmadas } = imagenesSlice.actions;

  export default imagenesSlice.reducer;
  