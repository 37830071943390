import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function CellIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 19.7 30.86"
            {...props}
        >
        <Path
            d="M16.68 0H3.03C1.36 0 0 1.36 0 3.03v24.81c0 1.67 1.36 3.03 3.03 3.03h13.65c1.67 0 3.03-1.36 3.03-3.03V3.03c0-1.67-1.36-3.03-3.03-3.03ZM9.86 29a2.066 2.066 0 0 1 0-4.13c1.13 0 2.07.93 2.07 2.06S11 29 9.86 29Zm7.06-7.15c0 1.24-1.01 2.25-2.25 2.25H5.04c-1.24 0-2.25-1.01-2.25-2.25V4.86c0-1.24 1.01-2.25 2.25-2.25h9.62c1.24 0 2.25 1.01 2.25 2.25v16.98Z"
            data-name="Capa 1"
            fill= {props.fill}
            />
        </Svg>
    )   
}