import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function EfectivoIcon(props) {
    return(
      <Svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Capa 2"
    viewBox="0 0 84.68 53.9"
    {...props}
  >
    <Defs>
      <LinearGradient
        id="a"
        x1={42.34}
        x2={42.34}
        y1={8.1}
        y2={47.75}
        data-name="Degradado sin nombre 157"
        gradientUnits="userSpaceOnUse"
      >
        <Stop offset={0} stopColor="#bbcd74" />
        <Stop offset={1} stopColor="#88a80d" />
      </LinearGradient>
    </Defs>
    <G data-name="Capa 1">
      <Rect
        width={84.68}
        height={53.9}
        rx={8.8}
        ry={8.8}
        style={{
          fill: "url(#a)",
        }}
      />
      <Path
        d="M73.56 3.7H11.12c-3.77 0-6.84 3.07-6.84 6.84v32.82c0 3.77 3.07 6.84 6.84 6.84h62.44c3.77 0 6.84-3.07 6.84-6.84V10.54c0-3.77-3.07-6.84-6.84-6.84Zm4.84 39.66c0 2.67-2.17 4.84-4.84 4.84H11.12c-2.67 0-4.84-2.17-4.84-4.84V10.54c0-2.67 2.17-4.84 4.84-4.84h62.44c2.67 0 4.84 2.17 4.84 4.84v32.82ZM48.47 26.97c-.89-.52-1.87-.94-2.94-1.27-.83-.25-1.66-.47-2.5-.68v-7.29c.76.07 1.55.21 2.36.43 1.21.33 2.38.83 3.52 1.5L50.4 16c-1.11-.72-2.42-1.28-3.92-1.67-1.14-.29-2.29-.48-3.44-.55V9.76h-2.97v4.08c-1.49.14-2.78.45-3.86.94-1.51.69-2.63 1.61-3.37 2.75-.74 1.15-1.11 2.41-1.11 3.79s.27 2.47.82 3.34c.54.87 1.26 1.56 2.16 2.08.89.52 1.88.95 2.96 1.29.8.25 1.61.47 2.41.66v7.2c-1.18-.1-2.34-.35-3.48-.75-1.5-.53-2.76-1.2-3.77-1.99l-1.63 3.66c1.09.89 2.53 1.63 4.33 2.19 1.46.46 2.97.72 4.55.81v4.03h2.97v-4.08c1.51-.14 2.82-.45 3.92-.95 1.52-.69 2.65-1.61 3.39-2.75.74-1.15 1.11-2.4 1.11-3.75s-.27-2.42-.82-3.28a6.125 6.125 0 0 0-2.16-2.07Zm-9.12-2.88c-.88-.27-1.6-.63-2.14-1.11-.54-.47-.82-1.09-.82-1.87 0-.63.19-1.2.56-1.72.38-.52.97-.94 1.79-1.25.38-.15.82-.26 1.3-.33v6.48c-.24-.07-.48-.13-.71-.2Zm6.87 10.24c-.39.5-.99.89-1.81 1.18-.4.14-.86.25-1.38.32v-6.4c.26.07.53.15.78.22.88.28 1.6.65 2.16 1.12.56.47.83 1.09.83 1.87 0 .63-.19 1.19-.58 1.69Zm-23.41-7.38c0 1.98-1.61 3.59-3.59 3.59s-3.59-1.61-3.59-3.59 1.61-3.59 3.59-3.59 3.59 1.61 3.59 3.59Zm46.22 0c0 1.98-1.61 3.59-3.59 3.59s-3.59-1.61-3.59-3.59 1.61-3.59 3.59-3.59 3.59 1.61 3.59 3.59Z"
        style={{
          fill: "#fcee21",
        }}
      />
    </G>
  </Svg>
    )
}