import React, {useState, useEffect, useRef} from 'react';
import {  View, Pressable } from 'react-native';
import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import FooterPagoOxxo from './FooterPagoOxxo';
import JsBarcode from 'jsbarcode';
import { useSelector, useDispatch } from "react-redux";

import OxxoIcon from '../../assets/icons/oxxo';

/* Iconos */
import { FontAwesome } from '@expo/vector-icons';


export default function FormaPagoOxxo({navigation}) {
    
    const barcodeRef = useRef(null);
    const Contrato = useSelector(state => state.contratos);
    const number = Contrato.contratos.oxxo_code;
    const paquete = Contrato.contratos.paquete;
    const noContrato = Contrato.contratos.numeroContrato;
    const monto = Contrato.contratos.monto_adeudo;
    const numeroTvs = Contrato.contratos.numeroTvs;
    const montoFormateado = monto.toFixed(2);
    //const monto = 150;
    useEffect(() => {
        if (barcodeRef.current) {
          JsBarcode(barcodeRef.current, number, {
            format: 'CODE128', 
            lineColor: '#000',
            width: 1.6,
            height: 50,
            displayValue: true,
          });
        }
      }, [number]);
    return (
        <>
        <View style={tw`w-full flex-grow  mt-15 pl-5 pr-5`}>
            <View style={tw`flex-row items-center`}>
              <OxxoIcon style={tw`mr-4`} width={125} height={75} />
              <View style={tw`flex-column ml-5`}>
                  <Text style={tw`text-xl font-500 text-primaryText mt-2 mb-2`}>MONTO A PAGAR:</Text>
                  <View style={tw`flex-row`}>
                    <Text style={tw`text-4xl font-700 text-primaryText`}>$ {montoFormateado}</Text>
                    <Text style={tw`text-LG font-700 text-primaryText mt-1`}> MXN</Text>
                  </View>
              </View>
            </View>
            <Text style={tw`text-xl text-datosText text-center mt-4`}>OXXO cobrará una comisión adicional al momento de realizar el pago</Text>
            {/* <Text style={tw`text-sm text-datosText text-center mt-4 mb-4`}>Vence el 27 de septiembre de 2024 a las 23:59</Text> */}
            <View style={tw`mt-8 items-center`}>
              <svg ref={barcodeRef}></svg>
            </View>
            <View style={tw`h-0.1 bg-gray-400 mt-8 ml-5 mr-5`}>
            </View>
            <Text style={tw`text-xl font-500 text-primaryText mt-8 ml-4`}>Instrucciones para Pago en OXXO</Text>
            <View style={tw`flex-row ml-4 mt-4`}>
              <Text style={tw`text-xl font-500 text-primaryText mr-2`}>1.</Text>
              <Text style={tw`text-xl text-datosText`}>
                Indica en la caja de OXXO que quieres <Text style={tw`font-500 text-primaryText`}>realizar un pago de servicios</Text>
              </Text>
            </View>
            <View style={tw`flex-row ml-4 mt-4`}>
              <Text style={tw`text-xl font-500 text-primaryText mr-2`}>2.</Text>
              <Text style={tw`text-xl text-datosText`}>
                <Text style={tw`font-500 text-primaryText`}>Muestra el código de barras </Text>al cajero para escanearlo
              </Text>
            </View>
            <View style={tw`flex-row ml-4 mt-4`}>
              <Text style={tw`text-xl font-500 text-primaryText mr-2`}>3.</Text>
              <Text style={tw`text-xl text-datosText`}>
              Realiza el pago correspondiente, ya sea en efectivo o con tarjeta de débito o crédito
              </Text>
            </View>
            <View style={tw`flex-row ml-4 mt-4`}>
              <Text style={tw`text-xl font-500 text-primaryText mr-2`}>4.</Text>
              <Text style={tw`text-xl text-datosText`}>
                Al confirmar tu pago, el cajero te entregara<Text style={tw`font-500 text-primaryText`}> Un comprobante impreso. En él podras 
                verificar que se haya realizado correctamente
                </Text>
              </Text>
            </View>
            <View style={tw`mt-8 rounded-lg p-3 items-center border border-primary bg-white mb-8`}>
              <Text style={tw`text-xl text-center text-datosText mb-4 mt-4`}>
                Sigue estos pasos para realizar tu pago
              </Text>
            </View>

        </View>
            {/* <FooterPagoOxxo navigation={navigation}/> */}
        </>
    );
  };

  
  