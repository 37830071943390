import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function DerechaIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 9.54 17.82"
            {...props}
        >
            <Path
            d="M1 17.82c-.26 0-.51-.1-.71-.29a.996.996 0 0 1 0-1.41l7.24-7.24L.39 1.71C0 1.32 0 .68.39.29S1.41-.1 1.8.29l7.14 7.14a2.006 2.006 0 0 1 0 2.84L1.7 17.51c-.2.2-.45.29-.71.29Z"
            data-name="Capa 1"
            />
        </Svg>
    )
}