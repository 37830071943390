import axios from 'axios';
import Constants from 'expo-constants'


export const documentosAPI = axios.create({
    baseURL: `${Constants.expoConfig?.extra?.API_URL_CONTRATOS+Constants.expoConfig?.extra?.API_ENVIRONMENT}`,
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': `${Constants.expoConfig?.extra?.API_KEY_CONTRATOS}`,
      },
});