import * as React from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';

import HeaderMetodoPagoEfectivo from './HeaderMetodoPagoEfectivo';
import FormaMetodoPagoEfectivo from './FormaMetodoPagoEfectivo';
import FooterMetodoPagoEfectivo from './FooterMetodoPagoEfectivo';

export default function MetodoPagoEfectivoScreen({navigation}) {
  

  return (
    <View style={tw`bg-white flex-1`}>
      <HeaderMetodoPagoEfectivo navigation={navigation} />
      <FormaMetodoPagoEfectivo navigation={navigation} />
    </View>
  );
}