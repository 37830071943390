import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function SucIcon(props) {
    return(
        <Svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_2"
    data-name="Capa 2"
    viewBox="0 0 85.34 53.54"
    {...props}
  >
    <Defs></Defs>
    <G id="Capa_1-2" data-name="Capa 1">
      <Path
        d="M19.37 3.65h58.16c4.31 0 7.8 3.5 7.8 7.8v37.74c0 2.39-1.94 4.34-4.34 4.34H23.71c-2.39 0-4.34-1.94-4.34-4.34V3.65Z"
        style={{
          fill: "#4a5e73",
        }}
      />
      <Path
        d="M39.6 29.2h24.34v24.34H39.6z"
        style={{
          fill: "#add8e6",
        }}
      />
      <Path
        d="M30.66 10.83h42.21v7.34H30.66z"
        style={{
          fill: "#edd15a",
        }}
      />
      <Path
        d="M38.13 19.37C38.13 9.02 29.73.62 19.37.62S.62 9.02.62 19.37s18.75 28.25 18.75 28.25 18.75-17.89 18.75-28.25Z"
        style={{
          fill: "#ff1b51",
        }}
      />
      <Path
        d="m19.37 48.48-.43-.41c-.77-.74-18.95-18.19-18.95-28.7S8.69 0 19.37 0s19.37 8.69 19.37 19.37-18.17 27.96-18.95 28.7l-.43.41Zm0-47.24c-10 0-18.13 8.13-18.13 18.13 0 9.12 15.35 24.65 18.13 27.38 2.78-2.74 18.13-18.27 18.13-27.38.01-10-8.13-18.13-18.13-18.13Z"
        style={{
          fill: "#e6e6e6",
        }}
      />
      <Circle cx={19.37} cy={18.28} r={10.44} className="cls-1" />
      <Path d="M51.15 30.18h1.24v22.37h-1.24z" className="cls-1" />
    </G>
  </Svg>
)
}