import { createSlice } from "@reduxjs/toolkit";

export const pagosTarjetaSlice = createSlice({
    name: 'pagosDomicilio',
    initialState: {
      statusPagoReportado: "no_reportado",
      isLoading: false,
      messagePagoTarjeta: "",
      errorMessage: ""
    },
    reducers: {
        startReportaPago: (state) => {
            state.statusPagoReportado="inicio_reporte";
            state.isLoading=true;
            state.messagePagoTarjeta="";
            state.errorMessage="";
        },
        setPagoReportaData: (state) => {
            state.statusPagoReportado="reportado";
            state.isLoading=false;
            state.messagePagoTarjeta="Pago reportado exitosamente";
            state.errorMessage="";
        },
        setPagoReportaDataError: (state, action) => {
            state.statusPagoReportado="reportado";
            state.isLoading=false;
            state.messagePagoTarjeta="Pago reportado con error";
            state.errorMessage=action.payload.errorMessage;
        },
        endPagoReportado: (state) => {
            state.statusPagoReportado="fin_reportado";
            state.isLoading=false;
            state.messagePagoTarjeta="";
            state.errorMessage="";
        },
    },
  });
  
  export const { startReportaPago, setPagoReportaData, setPagoReportaDataError, endPagoReportado } = pagosTarjetaSlice.actions;

  export default pagosTarjetaSlice.reducer;