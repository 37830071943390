import * as React from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';

import HeaderAltaDocumentosCompDomi from './HeaderAltaDocumentosCompDomi';
import FormaAltaDocumentosCompDomi from './FormaAltaDocumentosCompDomi';

export default function AltaDocumentosCompDomicilioScreen({navigation}) {
  

  return (
    <View style={tw`bg-bgGrey flex-1`}>
      <HeaderAltaDocumentosCompDomi navigation={navigation} />
      <FormaAltaDocumentosCompDomi navigation={navigation} />
    </View>
  );
}