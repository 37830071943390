import { StatusBar, } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';//Google analytics
import { useDispatch, useSelector } from 'react-redux';
import { SafeAreaView, View, ScrollView, TextInput, Pressable } from 'react-native'

import { SafeAreaProvider } from 'react-native-safe-area-context';

import useCachedResources from '../../hooks/useCachedResources';
import useColorScheme from '../../hooks/useColorScheme';
import Navigation from '../../navigation';

import Toast, { ToastProvider } from 'react-native-toast-notifications'

//  react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()

// Configuración twrnc
import tw from '../../styles/twrnc-config';
import { useDeviceContext } from 'twrnc'

/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';

//-------------------------Configuración redux-------------------------------------------//
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import configureStore from "../../store/configureStore";

const { store, persistor } = configureStore();
//--------------------------------------------------------------------------------------//

//-------------------------Configuración de API-------------------------------------------//
import api from "../../utils/api";
import Constants from 'expo-constants'

import HeaderDatos from './HeaderDatos';
import FooterDatos from './FooterDatos';
import Datos from './Datos';

export default function DatosPersonalesScreen({navigation}){
    const Contrato = useSelector( state => state.contratos );
    useEffect(() => {
        // Inicializa Google Analytics y registra la página
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });;
      }, []);
    
    return(
        <SafeAreaView style={tw`flex-1 bg-white`}>
            <ScrollView contentContainerStyle={tw``}>
                <HeaderDatos navigation={navigation} />
                <Datos navigation={navigation} Contrato={Contrato.contratos} />
                {/* Botones */}
                <FooterDatos navigation={navigation} />
            </ScrollView>
      </SafeAreaView>
    );
};