import React from 'react';
import { View } from 'react-native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import InfoIcon from '../../assets/icons/info';

export default function ContentInicio({ navigation }) {
    return (
        <View style={tw`flex-1 justify-center items-center p-4`}>
            <InfoIcon height={86} width={86} fill={'#E8E6E6'} style={tw`mb-4`} />
            <Text style={tw`text-primaryText text-lg text-center mt-4`}>
                El enlace solicitado no es válido
            </Text>
        </View>
    );
}
