import React from 'react';
import { View, Pressable } from 'react-native';
import { useRoute } from '@react-navigation/native';
import tw from '../../styles/twrnc-config';
import { MaterialIcons } from '@expo/vector-icons';
import Text from '../../components/Text';
import { useSelector } from 'react-redux';

export default function HeaderDatos({ navigation }) {
    // const route = useRoute();
    // const {idEmpresa, codigoActivacion } = route.params;
    const t = useSelector(state => state.token.t);

    function goToInicio() {
        navigation.navigate('InicioScreen.Index', { t });
    }

    return (
        <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
            <View style={tw.style(`flex-row`)}>
                <Pressable style={tw`mr-1 pt-9`} onPress={() => goToInicio()}>
                    <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
                </Pressable>
                <Text style={tw`text-primary text-3xl mt-8 font-500 `}>Información</Text>
            </View>
            <Text style={tw`text-primaryText mt-3 text-xl pl-9`}>Confirma que tu información sea correcta</Text>
        </View>
    );
};
