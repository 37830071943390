import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Image, ScrollView, View } from 'react-native'


import tw from '../../styles/tailwind';
import api from "../../utils/api"
import Text from '../../components/Text';




export default function ReactQueryScreen({ route, navigation }) {
  const { isLoading, error, data, status, fetchStatus, isFetching, refetch, isInitialLoading } = useQuery(
    ['dogs'], getDogs
  )


  async function getDogs({ queryKey }) {
    const params = {
      name: 'a',
    }

    const headers = {
      'X-Api-Key': 'QL0PRDiUpWlAYRW/U0fnxg==H9qbUYYG5GnhMsUs'
    }

    console.log("fetching - getDogs");

    return api.get("https://api.api-ninjas.com/v1/dogs", {
      params,
      headers
    })
    .then(response => response.data)
  }


  if (isLoading) return <Text style={tw`text-lg text-gray-600 mt-10 text-center`}>Cargando...</Text>

  if (error) return <Text>Ocurrió un error: {error.message}</Text>

  return (
    <ScrollView style={tw`mx-4 my-2`}>
      {
        data.map(dog => 
          <View key={dog.name} style={tw`row py-2 border-b border-gray-200`}>
            <Image source={{ uri: dog.image_link }} style={tw`w-15 h-15 mr-3 rounded`} />
            <Text>{dog.name}</Text>
          </View>
        )
      }
    </ScrollView>
  );
}
