import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import tw from '../../styles/twrnc-config';
import { useSelector, useDispatch } from "react-redux";
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';
import { subeImagenesFirmasData, setImagenesFirmadas } from '../../store/slices/imagenes';

const FooterFirma = ({ navigation, Contrato, numeroDoc }) => {
  const ImagenesData = useSelector(state => state.imagenes);
  const firmasData = useSelector(state => state.firmas);
  const contratoData = useSelector(state => state.selectedDoc);
  const dispatch = useDispatch();
  const documentosData = Contrato.documentos;

  const verificarFirmasCompletas = () => {
    const firmasUrls = firmasData.firmasUrls || []; 

    // Verificar que todos los documentos tengan una firma correspondiente en firmasUrls
    for (let i = 0; i < numeroDoc; i++) {
      if (!documentosData[i].fechaFirma && !firmasUrls[i]) {
        // Si encontramos un documento sin firma, regresamos false
        return false;
      }
    }

    // Si todos los documentos tienen firma, regresamos true
    if (firmasData.firmasUrls.length === 0){
      dispatch( setImagenesFirmadas({imagenesFirmadas: true}) );
    }
    return true;
  };

  const goToAltaDocumentos = async () => {
    if (verificarFirmasCompletas()) {
      //console.log("******* DATA IMAGENES FIRMADAS : ",ImagenesData.imagenesFirmadas)
      if(!ImagenesData.imagenesFirmadas){
        dispatch(subeImagenesFirmasData(firmasData.firmasUrls, contratoData.idActivacion, contratoData.id_empresa));
      }
      navigation.navigate('AltaDocumentosScreen.Index');
    } else {
      alert("Por favor termina de firmar todos los documentos");
    }
  };

  const firmasCompletas = verificarFirmasCompletas();

  return (
    <View style={[tw`flex-row w-full pr-5 pl-5 pb-6 pt-6 justify-center items-center shadow-lg bg-white`]}>
      <Pressable
        style={[tw`flex-1 py-3 rounded-full`, firmasCompletas ? tw`bg-primary` : tw`bg-buttonInac`, styles.buttonFooter]}
        onPress={goToAltaDocumentos}
      >
        <Text style={[tw`text-center text-xl`, firmasCompletas ? tw`text-white` : tw`text-textButton`]}>
          Continuar
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonFooter: {
    width: 150,
    height: 55,
  },
});

export default FooterFirma;
