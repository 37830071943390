import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import Icon from '../../components/Icon';
import { WorkSans_600SemiBold } from '@expo-google-fonts/work-sans';
import ModalAltaDocumentos from './ModalAltaDocumentos';
import * as Font from 'expo-font';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useRoute } from '@react-navigation/native';

/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';


export default function HeaderAltaDocumentos({navigation}) {
    // const route = useRoute();
    // const { idEmpresa, codigoActivacion } = route.params;
    const [visibleDocs, setVisibleDocs] = useState(false);

    const goToFirmas= () => {
        navigation.navigate('ListFirmasScreen.Index');
    }

    const openModalDocs = () => {
        console.log("Entra aqui ")
        setVisibleDocs(true);
    };

    const closeModalDocs = () => {
        setVisibleDocs(false);
    };

    /**
     * Ejemplo de petición a una API
     */
    function useTheAPI(folio: String, nombre_cliente: String){
      const data = { folio, nombre_cliente }
  
      api.post("/sells", data)
        .then((response) => {
          // OK!
        //   console.log("Venta creada!", response);
        })
        .catch(error => {
          // Error
        //   console.log("Error al crear venta", error)
        })
    }
  
    return (
        <View style={[tw`bg-encabezado w-full p-5 border-b border-primary shadow-lg`]}>
            <View style={tw`flex-row items-center`}>
                <Pressable style={tw`mr-2 pt-9`} onPress={goToFirmas}>
                    <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
                </Pressable>
                <Text style={tw`text-primary text-3xl mt-8 font-500`}>Tu Identificación</Text>
            </View>
            <Text style={[tw`mt-3 text-xl pl-10`]}>Necesitamos foto de tu INE por ambos lados</Text>
            <Pressable style={tw`mr-2 pt-4`} onPress={openModalDocs}>
            <Text style={[tw`text-lg pl-9 underline text-primary`]} >¿Por qué me piden esta información?</Text>
            </Pressable>
            <ModalAltaDocumentos visibleDocs={visibleDocs} closeModalDocs={closeModalDocs} />
        </View>           
    );
  };

  
  