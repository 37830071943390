import React, {useState, useEffect} from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native';

import api from "../../utils/api"
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useSelector } from 'react-redux';

import { LinearGradient } from 'expo-linear-gradient';
import TelecableLogo from '../../assets/icons/telecableLogo';

/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';


export default function HeaderPagoSucursal({navigation}) {
    const goToMetodoPago= () => {
        navigation.navigate('MetodoPagoScreen.Index');
    }
    const contratos = useSelector(state => state.contratos.contratos);
    //console.log(contratos);
    const logo_empresa = contratos.logo_empresa;
    
  
    return (
      <View style={[tw`w-full shadow-lg items-center justify-center`, { height: 350, borderBottomLeftRadius:50, borderBottomRightRadius:50 }]}>
     <LinearGradient
        colors={['#E6E6E6', '#FFFFFF', '#FFFFFF']} // Colores del degradado
        style={[tw`absolute inset-0 items-center justify-center shadow-lg`, {borderBottomLeftRadius: 50, borderBottomRightRadius: 50}]}
        start={{ x: 0.5, y: 0 }} // Punto de inicio del degradado (arriba)
        end={{ x: 0.5, y: 1 }}   // Punto de finalización del degradado (abajo)
      />
      <View style={tw``}>
        <Image source={{ uri: logo_empresa }} style={{ width: 400, height: 250, resizeMode: 'contain' }} />
        <View style={tw`pl-6 pr-6`}>
            <Text style={[tw`font-bold text-2xl mt-6 mb-10 text-center`]}>Gracias por completar su contrato</Text>
        </View>
      </View>
  </View>
    );
  };

  
  

