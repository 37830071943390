import React from 'react';
import { View, StyleSheet, Pressable, ActivityIndicator } from 'react-native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useRoute } from '@react-navigation/native';

const FooterFirmarDocs = ({ navigation, firmas, accepted, documento, terminarProceso, isLoading }) => {
  // const route = useRoute();
  // const { idEmpresa, codigoActivacion } = route.params;

  const isFirmaUploaded = firmas[documento]?.isFirmaUploaded;

  function goToFirmas() {
    if (isFirmaUploaded && accepted) {
      terminarProceso();
    } else {
      alert("Por favor agrega tu firma y acepta el estado de conformidad");
    }
  }

  return (
    <View style={[tw`flex-row w-full pr-5 pl-5 pb-6 pt-6 mt-6 justify-center items-center shadow-lg bg-white`]}>
      <View style={tw`w-full`}>
        {isFirmaUploaded && accepted ? (
          <Pressable style={[tw`flex-1 py-3 mx-2 rounded-full`,  isLoading ? tw`bg-encabezado` : tw`bg-primary`  ,styles.buttonFooter]} onPress={goToFirmas} disabled={isLoading}>
            {isLoading ? (
              <Text style={[tw`text-center text-datosText text-xl`]}>Cargando...</Text>
            ) : (
              <Text style={[tw`text-center text-white text-xl`]}>Terminar</Text>
            )}
          </Pressable>
        ) : (
          <Pressable style={[tw`flex-1 py-3 mx-2 bg-buttonInac rounded-full`, styles.buttonFooter]} onPress={goToFirmas}>
            <Text style={[tw`text-center text-textButton text-xl`]}>Terminar</Text>
          </Pressable>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonFooter: {
    height: 55,
  },
});

export default FooterFirmarDocs;
