import React from "react";
import { Text as ReactText } from "react-native";

import tw from "../styles/tailwind";


export default function Text(props) {
  const style = {
    fontFamily: "Montserrat_400Regular"
  }

  if (props.style?.fontWeight == "thin" || props.style?.fontWeight == "100") {
    props.style.fontWeight = "100"
    style.fontFamily = "Montserrat_100Thin"
  }
  else if (props.style?.fontWeight == "extra-light" || props.style?.fontWeight == "200") {
    props.style.fontWeight = "200"
    style.fontFamily = "Montserrat_200ExtraLight"
  }
  else if (props.style?.fontWeight == "light" || props.style?.fontWeight == "300") {
    props.style.fontWeight = "300"
    style.fontFamily = "Montserrat_300Light"
  }
  else if (props.style?.fontWeight == "regular" || props.style?.fontWeight == "400") {
    props.style.fontWeight = "400"
    style.fontFamily = "Montserrat_400Regular"
  }
  else if (props.style?.fontWeight == "medium" || props.style?.fontWeight == "500") {
    props.style.fontWeight = "500"
    style.fontFamily = "Montserrat_500Medium"
  }
  else if (props.style?.fontWeight == "semi-bold" || props.style?.fontWeight == "600") {
    props.style.fontWeight = "600"
    style.fontFamily = "Montserrat_600SemiBold"
  }
  else if (props.style?.fontWeight == "bold" || props.style?.fontWeight == "700") {
    props.style.fontWeight = "700"
    style.fontFamily = "Montserrat_700Bold"
  }
  else if (props.style?.fontWeight == "extra-bold" || props.style?.fontWeight == "800") {
    props.style.fontWeight = "800"
    style.fontFamily = "Montserrat_800ExtraBold"
  }
  else if (props.style?.fontWeight == "black") {
    props.style.fontWeight = "900"
    style.fontFamily = "Montserrat_900Black"
  }
  else if (props.style?.fontWeight == "thin-italic") {
    props.style.fontWeight = "100"
    style.fontFamily = "Montserrat_100Thin_Italic"
  }
  else if (props.style?.fontWeight == "extra-light-italic") {
    props.style.fontWeight = "200"
    style.fontFamily = "Montserrat_200ExtraLight_Italic"
  }
  else if (props.style?.fontWeight == "light-italic") {
    props.style.fontWeight = "300"
    style.fontFamily = "Montserrat_300Light_Italic"
  }
  else if (props.style?.fontWeight == "italic") {
    props.style.fontWeight = "400"
    style.fontFamily = "Montserrat_400Regular_Italic"
  }
  else if (props.style?.fontWeight == "medium-italic") {
    props.style.fontWeight = "500"
    style.fontFamily = "Montserrat_500Medium_Italic"
  }
  else if (props.style?.fontWeight == "semi-bold-italic") {
    props.style.fontWeight = "600"
    style.fontFamily = "Montserrat_600SemiBold_Italic"
  }
  else if (props.style?.fontWeight == "bold-italic") {
    props.style.fontWeight = "700"
    style.fontFamily = "Montserrat_700Bold_Italic"
  }
  else if (props.style?.fontWeight == "extra-bold-italic") {
    props.style.fontWeight = "800"
    style.fontFamily = "Montserrat_800ExtraBold_Italic"
  }
  else if (props.style?.fontWeight == "black-italic") {
    props.style.fontWeight = "900"
    style.fontFamily = "Montserrat_900Black_Italic"
  }


  return (
    <ReactText {...props} style={[style, props.style]} />
  )
}