import React, { useState, useEffect } from 'react';
import { View, Platform, Pressable } from 'react-native';
import SignatureCanvas from 'react-signature-canvas';
import { Overlay } from '@rneui/themed';
import tw from '../../styles/twrnc-config';
import { MaterialIcons } from '@expo/vector-icons';
import Text from '../../components/Text';
import { useSelector, useDispatch } from "react-redux";
import { subeImagenFirma, subeImagenFirma2, subeImagenFirma3 } from '../../store/slices/imagenes';

export const ModalFirma = ({
  visibleFirma,
  closeModalFirma,
  setFirma,
  documento,
  numeroDoc
}) => {
  const sigCanvas = React.useRef();
  const dispatch = useDispatch();
  const Contrato = useSelector(state => state.contratos);
  const ImagenesData = useSelector(state => state.imagenes);
  const id_activacion = Contrato.contratos.codigoActivacion;
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

  useEffect(() => {
    if (sigCanvas.current) {
      setIsCanvasEmpty(sigCanvas.current.isEmpty());
    }
  }, [sigCanvas.current]);

  const manejarFirma = () => {
    if (isCanvasEmpty) {
      alert("Por favor, dibuje su firma antes de continuar.");
      return;
    }

    // Obtener el canvas original de la firma
    const canvas = sigCanvas.current.getTrimmedCanvas();
    // Crear un nuevo canvas
    const newCanvas = document.createElement('canvas');
    newCanvas.width = canvas.width;
    newCanvas.height = canvas.height;
    const ctx = newCanvas.getContext('2d');

    // Dibuja un fondo blanco en el nuevo canvas
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);

    // Dibuja el contenido del canvas original en el nuevo canvas
    ctx.drawImage(canvas, 0, 0);

    // Convertir el nuevo canvas a JPEG
    const firma = newCanvas.toDataURL('image/jpeg');
    setFirma(firma);
    closeModalFirma(false);
  };

  const manejarBorrarFirma = () => {
    sigCanvas.current.clear();
    setIsCanvasEmpty(true);
  };

  const handleEndDrawing = () => {
    setIsCanvasEmpty(sigCanvas.current.isEmpty());
  };

  return (
    <Overlay
      isVisible={visibleFirma}
      windowBackgroundColor="rgba(0,0,0,0.5)"
      overlayBackgroundColor="transparent"
      overlayStyle={tw`bg-white border-0 justify-center items-center mt-22 pt-5 pb-5 pl-10 pr-10`}
    >
      <View style={[tw`flex-1 `]}>
        <View style={tw`flex-row w-full`}>
          <Pressable style={tw`mr-2`} onPress={() => closeModalFirma(false)}>
            <MaterialIcons name="arrow-back" size={28} color={tw.color("primary")} />
          </Pressable>
          <Text style={[tw`text-primaryText text-xl`]}>Dibuja tu firma</Text>
        </View>
        <View style={tw`border border-black rounded-lg mt-3`}>
          {Platform.OS === 'web' ? (
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{ className: 'sigCanvas', width: 300, height: 200 }}
              onEnd={handleEndDrawing}
            />
          ) : (
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{ width: 300, height: 200 }}
              onEnd={handleEndDrawing}
            />
          )}
        </View>
        <View style={tw`flex-row w-full`}>
          <Pressable
            style={[tw`mt-10 ml-1 flex-1 bg-white pt-5 pb-5 pl-4 pr-4 rounded-full justify-center border border-primary mr-1`]}
            onPress={manejarBorrarFirma}
          >
            <Text style={[tw`text-center text-primary text-xl`]}>Borrar</Text>
          </Pressable>
          <Pressable
            style={[
              tw`mt-10 flex-2 pt-5 pb-4 pl-4 pr-5 rounded-full justify-center mr-1 ml-1`,
              isCanvasEmpty ? tw`bg-buttonInac` : tw`bg-primary`
            ]}
            onPress={manejarFirma}
            disabled={isCanvasEmpty}
          >
            <Text style={[
              tw`text-center  text-xl`,
              isCanvasEmpty ? tw`text-textButton` : tw`text-white`
            ]}>Firmar</Text>
          </Pressable>
        </View>
      </View>
    </Overlay>
  );
};
