import * as React from "react"
import Svg, {  Defs,
    LinearGradient,
    Stop,
    G,
    Rect,
    Circle,
    Path, } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function OxxoIcon(props) {
    return(
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Capa 2"
            viewBox="0 0 74.3 37.66"
            {...props}
        >
            <G data-name="Capa 1">
            <Path
                d="M0 4.59C0 2.11 1.99.09 4.46.09h65.38c2.46 0 4.46 2.01 4.46 4.5v28.45c0 2.48-2 4.5-4.46 4.5H4.46c-2.47 0-4.46-2.01-4.46-4.5V4.59Z"
                style={{
                fill: "#fff",
                }}
            />
            <Path
                d="M.05 4.14C.05 1.85 1.89 0 4.15 0h66.06c2.26 0 4.1 1.85 4.1 4.14v.13H.05v-.13ZM74.3 33.42v.1c0 2.28-1.83 4.14-4.1 4.14H4.15c-2.26 0-4.1-1.85-4.1-4.14v-.1H74.3Z"
                style={{
                fill: "#fbb110",
                }}
            />
            <Path
                d="M51.44 18.79c0 5.78 4.64 10.47 10.37 10.47s10.37-4.69 10.37-10.47S67.53 8.32 61.81 8.32s-10.37 4.69-10.37 10.47Zm-49.46 0c0 5.78 4.65 10.47 10.38 10.47s10.37-4.69 10.37-10.47S18.08 8.32 12.36 8.32 1.98 13 1.98 18.79ZM74.3 31.44H23.16c.92-.65 1.78-1.55 2.72-2.75l5.21-6.71 2.28 2.9 2.53-3.4-2.21-2.84 5.3-6.83c1.68-2.17-1.54-4.71-3.22-2.55l-4.69 6.04-4.76-6.12c-1.69-2.16-4.9.39-3.21 2.56l5.38 6.91-5.83 7.5c-2.07 2.67-4.14 5.11-8.24 5.3H.05V6.25h50.88c-.87.64-1.7 1.51-2.6 2.66l-5.21 6.71-2.28-2.9-2.53 3.4 2.21 2.84-5.3 6.83c-1.68 2.17 1.54 4.71 3.22 2.55l4.69-6.04 4.76 6.12c1.69 2.16 4.9-.39 3.21-2.55l-5.38-6.91 5.83-7.5c1.9-2.45 3.8-4.72 7.28-5.21H74.3v25.19ZM55.6 18.79c0-3.46 2.78-6.27 6.21-6.27s6.21 2.81 6.21 6.27-2.78 6.27-6.21 6.27-6.21-2.81-6.21-6.27Zm-49.45 0c0-3.46 2.78-6.27 6.21-6.27s6.21 2.81 6.21 6.27-2.78 6.27-6.21 6.27-6.21-2.81-6.21-6.27"
                style={{
                fill: "#e70020",
                }}
            />
            </G>
        </Svg>
    )
}