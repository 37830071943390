import { createSlice } from "@reduxjs/toolkit";

export const firmasSlice = createSlice({
    name: 'firmas',
    initialState: {
      firmas: [],
      firmasUrls: [],
      statusFirma: "no_iniciado",
      isLoading: false,
      errorMessage: ""
    },
    reducers: {
        setEmptyFirmaDocumento: (state) => {
            state.firmas= [];
            state.firmasUrls= [],
            state.statusFirma= "no_iniciado";
            state.isLoading= false;
            state.errorMessage= "";
        },
        startFirmaDocumento: (state) => {
            state.isLoading = true;
            state.statusFirma = "generando_link";
        },
        getFirmaDocumento: (state, action) => {
            state.isLoading = false
            state.firmas = action.payload.firmas;
            state.statusFirma = "link";
            state.errorMessage="";
        },
        errorFirmaDocumento: (state, action) => {
            state.isLoading = false;
            state.statusFirma = "link_no_generado";
            state.errorMessage=action.payload.errorMessage;
        },
        setFirmaDocumento: ( state, action ) => {
            state.isLoading = false;
            state.firmas = action.payload.firmas;
            state.statusFirma = "link_generado";
        },
        setFirmaFirmada: ( state, action ) => {
            state.isLoading = false;
            state.firmas = action.payload.firmas;
            state.statusFirma = "firmada";
        },
        setFirmaURL: ( state, action ) => {
            //console.log("*************",action.payload.newFirma);
            state.isLoading = false;
            if(!state.firmasUrls.includes(action.payload.newFirma)){
                state.firmasUrls = [...state.firmasUrls, action.payload.newFirma] ;
            }
            state.statusFirma = "add_firma_url";
        },
        endRecoverFirmas: (state, /* action */) => {
            state.isLoading = false
        }
    },
  });
  
  export const { setEmptyFirmaDocumento, startFirmaDocumento, getFirmaDocumento, errorFirmaDocumento, setFirmaDocumento, setFirmaFirmada, setFirmaURL, endRecoverFirmas } = firmasSlice.actions;

  export default firmasSlice.reducer;
  