import { StatusBar, } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { SafeAreaView, View, Pressable, TextInput } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from '../../hooks/useCachedResources';
import useColorScheme from '../../hooks/useColorScheme';
import Navigation from '../../navigation';
import Toast, { ToastProvider } from 'react-native-toast-notifications'
//  react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
const queryClient = new QueryClient()
// Configuración twrnc
import tw from "../../styles/tailwind";
import { useDeviceContext } from 'twrnc'
//-------------------------Configuración redux-------------------------------------------//
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "../../store/configureStore";
const { store, persistor } = configureStore();
//-------------------------Configuración de API-------------------------------------------//
import api from "../../utils/api";
import Constants from 'expo-constants'
/* Iconos */
import { MaterialIcons } from '@expo/vector-icons';
import { Montserrat_400Regular } from '@expo-google-fonts/montserrat';

import HeaderValidar from './HeaderValidar'
import Text from '../../components/Text';

import { useRoute } from '@react-navigation/native';
import { useSelector } from 'react-redux';

export default function ContentValidar({navigation, contrato}) {
    /* Estado que valida si el pago fue correcto o no */
    let pagoValidado = false;
    const route = useRoute();
    const { idEmpresa, codigoActivacion } = route.params;
    const numeroContrato = Contrato.contratos.numeroContrato;
    const [contratoNombre, setContratoNombre] = useState(''); 
    const [contratoNumero, setContratoNumero] = useState(''); 
    const t = useSelector(state => state.token.t);

    useEffect(() => {
        if (contrato.nombre !== undefined) {
            setContratoNombre(contrato.nombre);
        }

        if (contrato.numeroContrato !== undefined) {
            setContratoNumero(contrato.numeroContrato);
        }
    }, [contrato]);

    let estadoContrato = "noPagado";

    return(
        <View style={tw`w-full flex-1  items-center`}>
            <View style={tw`w-full p-6`}>
                <View style={tw`p-6 `}>
                    {pagoValidado ? (
                            <View>
                                <Text style={tw`text-primaryText text-xl text-left mt-2`}>Se ha enviado comprobante de pago y el contrato a su correo electrónico.</Text>
                                <Text style={tw`text-primaryText text-xl font-500 text-center mb-2 mt-16`}>Número de contrato</Text>
                                <Text style={tw`bg-tabs text-datosText text-2xl text-center py-2 rounded-lg tracking-widest`}>{numeroContrato}</Text>
                            </View>
                        ) : (
                            <Text style={tw`text-primaryText text-xl text-left mt-2`}>Algo salió mal al procesar el pago, puede reintentar o elegir otro método de pago.</Text>
                        )
                    }
                </View>
            </View>
            {pagoValidado ? (
                    <View style={tw`w-full flex-1 justify-center mt-16 bg-white p-6 shadow-lg`}>
                        <Pressable style={tw`bg-primary py-4 rounded-full`} onPress={() => goToInicio()}>
                            <Text style={tw`text-center text-xl  text-white text-xl`}>Terminar</Text>
                        </Pressable>
                    </View>
                ): (
                    <View style={[tw`flex-1 justify-center `]}>
                        <Pressable style={tw`bg-white p-6 rounded-full border border-primary`} onPress={() => goToInicio()}>
                            <Text style={tw`text-center text-xl text-primary text-xl`}>Reintentar</Text>
                        </Pressable>
                        <Pressable style={tw`bg-primary p-6 rounded-full mt-4`} onPress={() => goToMetodoPago()}>
                            <Text style={tw`text-center text-xl  text-white text-xl`}>Cambiar método de pago</Text>
                        </Pressable>
                    </View>
                )
            }
        </View>
    );

    function goToInicio(){
        navigation.navigate('InicioScreen.Index' , { t });
    }
    function goToMetodoPago(){
        navigation.navigate('MetodoPagoScreen.Index');
    }
};

