import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function CheckIcon(props) {
    return(
        <Svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Capa 2"
        viewBox="0 0 38.22 38.22"
        {...props}
      >
        <G data-name="Capa 1">
          <Circle
            cx={19.11}
            cy={19.11}
            r={19.11}
            style={{
              fill: {props},
            }}
          />
          <Path
            d="M16.71 25.82c-.4 0-.78-.16-1.06-.44l-5.23-5.23a1.49 1.49 0 0 1 0-2.12 1.49 1.49 0 0 1 2.12 0l4.17 4.17 8.71-8.71a1.49 1.49 0 0 1 2.12 0c.59.59.59 1.54 0 2.12l-9.77 9.77c-.28.28-.66.44-1.06.44Z"
            style={{
              fill: "#fff",
            }}
          />
        </G>
      </Svg>
    )   
}