import * as React from 'react';
import { Button, StyleSheet, TextInput, View, Pressable, Image } from 'react-native'

import api from "../../utils/api"
import tw from '../../styles/tailwind';
import Text from '../../components/Text';
import Icon from '../../components/Icon';

import HeaderCompletaPagoEfectivo from './HeaderCompletaPagoEfectivo';
import FormaCompletaPagoEfectivo from './FormaCompletaPagoEfectivo';

export default function CompletaPagoEfectivoScreen({navigation}) {
  

  return (
    <View style={tw`bg-white flex-1`}>
      <HeaderCompletaPagoEfectivo navigation={navigation} />
      <FormaCompletaPagoEfectivo navigation={navigation} />
    </View>
  );
}