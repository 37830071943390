const { plugin } = require('twrnc');

module.exports = {
    content: ["./src/**/*.{js,jsx,ts,tsx}"],
    theme: {
        extend: {
            colors: {
                primary: '#45BA47',
                red: {
                    DEFAULT: '#f11',
                },
                background: '#fafafa',
                encabezado: '#E9F7E9',
                tabs: '#E3F5EA',
                textButton: '#E8E6E6',
                buttonInac: '#919191',
                primaryText: '#232323',
                datosText: '#6F6F6F',
                bgGrey: '#F7F7F7',
                
                'ocean-green': {
                    DEFAULT: '#48BB78',
                    '50': '#EFF9F3',
                    '100': '#DDF2E6',
                    '200': '#B8E4CA',
                    '300': '#92D7AF',
                    '400': '#6DC993',
                    '500': '#48BB78',
                    '600': '#389860',
                    '700': '#2B7249',
                    '800': '#1D4D31',
                    '900': '#0F2819',
                },
            },
            letterSpacing: {
                tighter: '-0.05em',
                tight: '-0.025em',
                normal: '0em',
                wide: '0.025em',
                wider: '0.05em',
                widest: '0.1em',
            },
            
        },
    },
    plugins: [
        plugin(({ addUtilities }) => {
            addUtilities({
                row: {
                    flexDirection: 'row',
                    alignItems: 'center',
                },
            });
        }),
    ],
};
