import React, { useState, useEffect } from 'react';
import { View, TextInput } from 'react-native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { Montserrat_400Regular } from '@expo-google-fonts/montserrat';

export default function ContentInicio({ navigation, contrato }) {
    const [contratoNombre, setContratoNombre] = useState(''); 
    const [contratoNumero, setContratoNumero] = useState(''); 

    useEffect(() => {
        if (contrato.nombre !== undefined) {
            setContratoNombre(contrato.nombre);
        }

        if (contrato.numeroContrato !== undefined) {
            setContratoNumero(contrato.numeroContrato);
        }
    }, [contrato]);

    let estadoContrato = "noPagado";
    return (
        <View style={tw`w-full p-6 flex-grow`}>
            <View style={tw`p-6`}>
                <Text style={tw`text-primaryText text-4xl text-left font-semibold`}>Bienvenid@</Text>
                <Text style={tw`text-primaryText text-4xl text-left font-semibold`}>{contratoNombre}</Text>
                {estadoContrato == "noPagado" ? (
                    <Text style={tw`text-primaryText text-xl text-left mt-6`}>Ayúdanos a terminar la contratación de tu servicio.</Text>
                ) : (
                    <Text style={tw`text-primaryText text-xl text-left mt-2`}>Ha finalizado exitosamente su trámite</Text>
                )}
                <Text style={tw`text-datosText text-lg text-center mb-4 mt-16`}>Número de contrato</Text>
                <Text style={tw`bg-tabs text-datosText text-2xl text-center py-2 rounded-lg tracking-widest`}>{contratoNumero}</Text>
            </View>
        </View>
    );
}
