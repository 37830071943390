import React, {useState} from 'react';
import { ScrollView, View } from 'react-native'


import tw from '../../styles/tailwind';
import { useForm } from 'react-hook-form';

import Text from '../../components/Text';

import Input from '../../components/Forms/Input';
import Button from '../../components/Forms/Button';




export default function FormExampleScreen({ route, navigation }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState();

  /*
    https://react-hook-form.com/get-started
    https://react-hook-form.com/api/useform
  */
  const { control, handleSubmit, formState, formState: { errors }, setError, setValue, watch } = useForm();


  function onSubmit(data){
    console.log(data);
    
    setData(data)
  }


  return (
    <ScrollView style={tw`bg-gray-200`} contentContainerStyle={tw`mx-3 my-4`}>

      <Input
        name='name'
        control={control}
        placeholder="Nombre"
        rules={{required: true}}
      />

      <Input
        name='address'
        control={control}
        placeholder="Monto numérico (xxx.xx)"
        rules={{ required: "Monto Requerido", pattern: { value: /^[0-9]+(\.[0-9]{1,2})?$/, message: "Monto inválido" } }}
      />

      

      <Button
        onPress={handleSubmit(onSubmit)}
        label="Submit Form"
        disabled={isSubmitting}
      />

      <Text style={tw`mt-3`}>{JSON.stringify(data)}</Text>
    </ScrollView>
  );
}
