import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Pressable, FlatList } from 'react-native';
import { useRoute } from '@react-navigation/native';
import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';
import { useDispatch, useSelector } from 'react-redux';
import FooterFirma from './FooterFirmas';
import CheckFirmas from '../../assets/icons/check';
import DerechaIcon from '../../assets/icons/derecha';
import { setSelectedDoc } from '../../store/slices/selectedDoc/selectedDocSlice';


const styles = StyleSheet.create({
  textOverlay: {
    top: '27%',
    left: '90%',
    transform: [{ translateX: -50 }, { translateY: -50 }],
    borderColor: "#000",
  },
  touchableOverlay: {
    top: 15,
    left: 10,
    borderColor: "#000",
    borderBottomRightRadius: 20,
    width: "100%",
  },
  textNameDocument: {
    top: -20,
    left: 0,
    width: "100%",
    fontSize: 16,
    color: "#000",
  },
  imageSuccess: {
    top: -47,
    left: 265,
  },
  imageFlechaDerecha: {
    top: -43,
    left: 270,
  },
  ListOverlay: {
    width: "100%",
    left: 30,
  },
});

export default function DocFirmas({ navigation, Contrato, Firmas }) {
  const dispatch = useDispatch();
  const documentosData = Contrato.documentos;
  // const route = useRoute();
  // const { idEmpresa, codigoActivacion } = route.params;
  const ImagensData =useSelector(state => state.imagenes);

  const firmasData = useSelector(state => state.firmas);
  const numeroDoc = documentosData.length;
  
// console.log(ImagensData);
  const openFirmarDoc = (documentoFimar, noDoc, idDocumento) => {
    dispatch(setSelectedDoc({ documentoFimar, noDoc, idDocumento }));
    navigation.navigate('FirmarDocumentosScreen.Index');
  };
  const renderIcon = (isSigned, index) => {
    const firmasUrls = firmasData.firmasUrls || []; 
    return isSigned || firmasUrls[index] ? (
      <CheckFirmas style={[tw`mt-1`]} fill={'#45ba47'} width={18} height={18} />
    ) : (
      <DerechaIcon style={[tw`mt-1`]} width={17} height={17} />
    );
      
  };
  return (
    <>
      <View style={tw`flex-grow w-full bg-bgGrey mt-4 pl-3 pr-3 mb-8 justify-between`}>
        <FlatList
          style={[tw`w-full pl-4 pr-4`]}
          data={documentosData}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => (
            <View style={[tw`bg-white rounded-2xl shadow-lg justify-center mt-4 mb-4`]}>
              <Pressable
                style={[tw`justify-between flex-row pb-6 pt-6 pl-8 pr-8`]}
                onPress={() => openFirmarDoc(item.nombre, index, item.idDocumento)}
              >
                <Text style={[tw`text-xl text-primaryText`]}>{item.nombre}</Text>
                {renderIcon(item.fechaFirma, index)}
              </Pressable>
            </View>
          )}
        />
      </View>
      <FooterFirma navigation={navigation} Contrato={Contrato} numeroDoc={numeroDoc} />
    </>
  );
}
