import { contratosAPI } from '../../api/contratosApi';
import { startReportaPago, setPagoReportaData, setPagoReportaDataError, endPagoReportado } from './pagosTarjetaSlice';
import Constants from 'expo-constants'

export const setPagoTarjetaReportado = (idEmpresa:string,  idActivacion:string, idAdeudo:string, orden_id:string) => {
    return async( dispatch:any ) => {
        dispatch( startReportaPago() );

        const responseData = await contratosAPI.post(`/contratos/make_pay`, 
            {
                idEmpresa: idEmpresa,
                idActivacion: idActivacion,
                idAdeudo: idAdeudo,
                orden_id: orden_id
            }
        )
        .then(data => {
            if(data.data.register==="OK"){
                dispatch( setPagoReportaData());
                dispatch( endPagoReportado() );
                return {"Success": true, "messagePagoReporte": "Mensaje reportado exitosamente"};
            }else{
                dispatch( setPagoReportaDataError({
                    errorMessage: data.data.errorMessage
                }));
                dispatch( endPagoReportado() );
                return {"Success": false, "messagePagoReporte": data.data.Message};
            }
        })
        .catch(error => {
            dispatch( setPagoReportaDataError({
                errorMessage: error
            }));
            dispatch( endPagoReportado() );
            return {"Success": false, "messagePago": error};
        });

        return responseData;
    }
}