import * as React from "react"
import Svg, { Defs, G, Circle, Path } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
export default function TvIcon(props) {
    return(
        <Svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        data-name="Capa 2"
        viewBox="0 0 27.8 26.04"
        {...props}
    >
        <Defs></Defs>
        <G id="Capa_1-2" data-name="Capa 1">
        <Path
            d="M27.19 0H.6C.27 0 0 .27 0 .6v20.79c0 .33.27.6.6.6h26.59c.33 0 .6-.27.6-.6V.6c0-.33-.27-.6-.6-.6Zm-1.75 19.07c0 .33-.27.6-.6.6H2.96c-.33 0-.6-.27-.6-.6V2.93c0-.33.27-.6.6-.6h21.88c.33 0 .6.27.6.6v16.14ZM20.88 26.04c.33 0 .6-.27.6-.6v-2.11c0-.33-.27-.6-.6-.6H6.92a.61.61 0 0 0-.61.6v2.11c0 .33.27.6.61.6h13.96Z"
            className="cls-1"
            fill= {props.fill}
        />
        </G>
    </Svg>
    )   
}